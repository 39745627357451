import { Button, Stack } from "@mui/material";
import { ChatMessage } from "@super-real/types";
import { FC, useEffect, useState } from "react";
import { useChatMessageAction } from "../hooks/useChatMessageAction";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { useSendChatMessage } from "../hooks/useSendChatMessage";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

interface Props {
  chatMessages: ChatMessage[];
}

export const ChatMessageSuggestionsView: FC<Props> = (props) => {
  const { chatMessages } = props;
  const talent = useChatPageContext((state) => state.talent);
  const hasChatMessageAction = useChatMessageAction((state) => !!state.action);
  const hasUserInteraction = chatMessages.some((m) => m.senderType === "USER");
  const { sendChatMessage } = useSendChatMessage({ isSuggestion: true });
  const [isVisible, setIsVisible] = useState(false);

  const isVisibleAfterTimeout =
    !hasUserInteraction &&
    !!talent?.chatMessageSuggestions?.length &&
    !hasChatMessageAction;

  useEffect(() => {
    if (!isVisibleAfterTimeout) return;

    const timeout = setTimeout(() => {
      setIsVisible(true);
      setTimeout(() => {
        const { scrollRef } = useChatPageContext.getState();
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 1); // Important! To scroll in Safari, the timeout must be > 0
    }, 2_000);
    return () => clearTimeout(timeout);
  }, [isVisibleAfterTimeout]);

  if (!isVisible) return null;
  if (!isVisibleAfterTimeout) return null;

  return (
    <ChatMessageLayoutView
      senderType="USER"
      actions={
        <Stack spacing={1}>
          {talent.chatMessageSuggestions?.map((suggestion) => (
            <Button
              variant="outlined"
              key={suggestion.id}
              sx={{
                borderRadius: 4,
                whiteSpace: "normal",
                alignSelf: "end",
                textAlign: "left",
                fontSize: 12,
              }}
              onClick={() => sendChatMessage(suggestion.text)}
            >
              {suggestion.text}
            </Button>
          ))}
        </Stack>
      }
    />
  );
};
