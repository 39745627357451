import { TalentCategory } from "@super-real/types";

interface RecordValue {
  label: string;
}

export const TALENT_CATEGORY_RECORD: Record<TalentCategory, RecordValue> = {
  INSTA: {
    label: "💃🏻 Insta Famous",
  },
  CUTE: {
    label: "🎀 Cute",
  },
  MATURE: {
    label: "🐯 Mature",
  },
  ASIAN: {
    label: "🌸 Asian",
  },
  BLONDE: {
    label: "👩 Blonde",
  },
  ANIME: {
    label: "⛩️ Anime",
  },
  GERMAN: {
    label: "🇩🇪 German",
  },
  EBONY: {
    label: "👸🏾 Ebony",
  },
  BRUNETTE: {
    label: "💁🏻‍♀️ Brunette ",
  },
  DOMINANT: {
    label: "🗡️ Dominant",
  },
  CURVY: {
    label: "🍑 Curvy",
  },
};
