import { ExpenseAction } from "@super-real/types";
import { isAnonymous as isAnonymousFn } from "../../Common/helpers/isAnonymous";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useFirebaseAuthDialog } from "../../Firebase/hooks/useFirebaseAuthDialog";
import { useChatMessageAction } from "../hooks/useChatMessageAction";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { handleNotEnoughCreditsAppDownloadDialog } from "./handleNotEnoughCreditsAppDownloadDialog";

export function handleNotEnoughCredits(action: ExpenseAction) {
  const { subscription, talent, products } = useChatPageContext.getState();
  const userSubscription = subscription;
  const talentSubscriptionProduct = products.find(
    (p) =>
      p.id === talent?.subscriptionProductId && p.priceType === "SUBSCRIPTION"
  );
  const isAnonymous = isAnonymousFn(auth.currentUser);

  if (isAnonymous) {
    return useFirebaseAuthDialog.getState().open();
  }

  handleNotEnoughCreditsAppDownloadDialog();

  if (action === "UNLOCK_AUDIO" || action === "UNLOCK_IMAGE") {
    if (talentSubscriptionProduct?.credits && !userSubscription) {
      return useChatMessageAction.getState().show("CHECKOUT_SUBSCRIPTION");
    } else {
      return useChatMessageAction.getState().show("CHECKOUT_ONE_TIME");
    }
  }

  if (action === "SEND_MESSAGE" && userSubscription?.status === "PAUSED") {
    // TODO: Resume subscription instead!
    return useChatMessageAction.getState().show("CHECKOUT_ONE_TIME");
  }

  if (action === "SEND_MESSAGE" && talentSubscriptionProduct) {
    return useChatMessageAction.getState().show("CHECKOUT_SUBSCRIPTION");
  }

  return useChatMessageAction.getState().show("CHECKOUT_ONE_TIME");
}
