import ShoppingCartCheckoutRoundedIcon from "@mui/icons-material/ShoppingCartCheckoutRounded";
import { Button, Chip, Grid, Stack, styled, Typography } from "@mui/material";
import { Product, Talent } from "@super-real/types";
import { FC, useCallback, useState } from "react";
import { useChatPageContext } from "../../Chat/hooks/useChatPageContext";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useCheckoutRedirect } from "../hooks/useCheckoutRedirect";

interface Props {
  talent: Talent;
}

export const CheckoutOneTimeView: FC<Props> = (props) => {
  const { talent } = props;
  const products = useChatPageContext((state) => state.products);
  const isUserAdmin = useMyUser((state) => state.user?.role === "ADMIN");
  const filteredProducts = products
    .reduce<(Product & { priceType: "ONE_TIME" })[]>((result, product) => {
      if (!product.isActive) return result;
      if (product.priceType !== "ONE_TIME") return result;
      if (product.isAdmin && !isUserAdmin) return result;

      result.push(product);
      return result;
    }, [])
    .sort((a, b) => a.price.amount - b.price.amount);
  const { redirect, isRedirecting } = useCheckoutRedirect({ talent });
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  const onClickRedirectToCheckout = useCallback(() => {
    if (!filteredProducts.length) return;
    if (!filteredProducts[selectedProductIndex]) return;
    void redirect(filteredProducts[selectedProductIndex]);
  }, [redirect, filteredProducts, selectedProductIndex]);

  return (
    <Stack py={2}>
      <Grid container spacing={1} mb={2}>
        {filteredProducts.map((product, index) => (
          <Grid key={product.id} item xs={4}>
            <TileButton
              sx={{ opacity: isRedirecting ? 0.6 : 1 }}
              selected={Number(selectedProductIndex === index)}
              disabled={isRedirecting}
              onClick={() => {
                if (selectedProductIndex === index) {
                  onClickRedirectToCheckout();
                } else {
                  setSelectedProductIndex(index);
                }
              }}
            >
              {product.discount && (
                <DiscountChip
                  size="small"
                  color="secondary"
                  label={product.discount}
                />
              )}
              <Typography variant="ah5" noWrap>
                💎 {product.credits}
              </Typography>
              <Typography variant="h4" color="secondary">
                {toLocaleMoney(product.price)}
              </Typography>
            </TileButton>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="info"
        size="large"
        startIcon={<ShoppingCartCheckoutRoundedIcon />}
        onClick={onClickRedirectToCheckout}
        disabled={isRedirecting}
      >
        Buy for {toLocaleMoney(filteredProducts[selectedProductIndex]?.price)}
      </Button>
    </Stack>
  );
};

const TileButton = styled(Button)<{ selected?: number }>(
  ({ theme, selected }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 16,
    borderRadius: 12,
    border: "2px solid transparent",
    borderColor: selected ? theme.palette.info.light : "transparent",
    background: theme.palette.divider,
  })
);

const DiscountChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  marginTop: theme.spacing(-1.5),
  marginRight: theme.spacing(-1),
  fontSize: 12,
  fontWeight: 600,
}));
