import { ChatMessage, Talent } from "@super-real/types";
import { useEffect, useState } from "react";
import { usePrevious } from "../../Common/hooks/usePrevious";
import { useMainInit } from "../../Main/hooks/useMainInit";
import { generateInitialChatMessages } from "../helpers/generateInitialChatMessages";
import { useChatMessageVisibility } from "./useChatMessageVisibility";

interface Params {
  talent?: Talent;
}

export function useInitialChatMessages(
  params: Params
): ChatMessage[] | undefined {
  const { talent } = params;
  const previousTalent = usePrevious(talent);
  const [initialChatMessages, setInitialChatMessages] =
    useState<ChatMessage[]>();

  useEffect(() => {
    if (!useMainInit.getState().isNewUser) return;
    if (!talent) return;
    if (talent.id === previousTalent?.id) return;

    const nextInitialChatMessages = generateInitialChatMessages(talent);

    useChatMessageVisibility.getState().hide(nextInitialChatMessages);
    setInitialChatMessages([...nextInitialChatMessages].reverse());
  }, [previousTalent, talent]);

  return initialChatMessages;
}
