import { Chat } from "@super-real/types";
import { create } from "zustand";

interface State {
  chat?: Chat;
  resolvers: ((result: Chat) => void)[];
  getChat: () => Promise<Chat>;
  setChat: (nextChat?: Chat) => void;
}

export const useAsyncChat = create<State>((set, get) => ({
  resolvers: [],
  getChat: async () => {
    const { chat } = get();
    if (chat) return chat;

    return new Promise<Chat>((resolve) => {
      get().resolvers.push(resolve);
    });
  },
  setChat: (nextChat) => {
    const previousChat = get().chat;

    if (!nextChat) return;
    if (nextChat.id === previousChat?.id) return;

    get().resolvers.forEach((resolve) => {
      resolve(nextChat);
    });

    get().resolvers = [];
    get().chat = nextChat;
  },
}));
