import { logEvent as logFirebaseAnalyticsEvent } from "firebase/analytics";
import { analytics } from "../../Firebase/consts/FIREBASE";

interface Params {
  stripePriceId: string;
  currency: string;
  value: number;
}

export function logPurchaseEvent(params: Params) {
  const { currency, value, stripePriceId } = params;
  if (!analytics) return;

  try {
    logFirebaseAnalyticsEvent(analytics, "purchase" as string, {
      affiliation: "Stripe",
      currency,
      value,
      items: [
        {
          item_id: stripePriceId,
          quantity: 1,
        },
      ],
    });
  } catch {
    // Do not throw an error!
  }
}
