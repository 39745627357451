import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CloudIcon from "@mui/icons-material/Cloud";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { getFirestoreUrl } from "../../Firebase/helpers/getFirestoreUrl";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useChatPageContext } from "../hooks/useChatPageContext";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const ChatHeadAdminDialog: FC<Props> = (props) => {
  const { isOpen, close } = props;
  const talent = useChatPageContext((state) => state.talent);
  const chat = useChatPageContext((state) => state.chat);
  const userId = useMyUser((state) => state.user?.id);

  return (
    <DrawerDialog open={isOpen} onClose={close}>
      <DialogContent>
        <Stack flexDirection="row">
          <Stack flex={1} spacing={1} alignItems="flex-start">
            <Typography variant="ah3">Firestore</Typography>
            {userId && (
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(`users/${userId}`)}
                startIcon={<PersonIcon />}
              >
                User
              </Button>
            )}
            {chat && (
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(`chats/${chat.id}`)}
                startIcon={<ChatBubbleIcon />}
              >
                Chat
              </Button>
            )}
            {chat && (
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(`chatConfigs/${chat.id}`)}
                startIcon={<ChatBubbleOutlineOutlinedIcon />}
              >
                Chat Config
              </Button>
            )}
            {talent && (
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(`talents/${talent.id}`)}
                startIcon={<StarIcon />}
              >
                Talent
              </Button>
            )}
            {talent && (
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(`talentConfigs/${talent.id}`)}
                startIcon={<StarBorderOutlinedIcon />}
              >
                Talent Config
              </Button>
            )}
          </Stack>
          <Stack flex={1} spacing={1} alignItems="flex-start">
            <Typography variant="ah3">Admin</Typography>
            <Button
              component="a"
              href={`${ENVIRONMENT.talentDomain}/app`}
              startIcon={<CloudIcon />}
            >
              App
            </Button>
            {!!userId && (
              <Button
                component="a"
                href={`${ENVIRONMENT.talentDomain}/users/${userId}`}
                startIcon={<PersonIcon />}
              >
                User
              </Button>
            )}
            {talent && (
              <Button
                component="a"
                href={`${ENVIRONMENT.talentDomain}/talents/${talent.id}`}
                startIcon={<StarIcon />}
              >
                Talent
              </Button>
            )}
            {chat && (
              <Button
                component="a"
                href={`${ENVIRONMENT.talentDomain}/chats/${chat.id}`}
                startIcon={<ChatBubbleIcon />}
              >
                Chat
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Go back
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
