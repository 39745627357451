import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, useEffect } from "react";
import { logEvent } from "../../Common/helpers/logEvent";
import { DrawerDialog } from "../../Common/views/DrawerDialog";

interface Props {
  isOpen: boolean;
  close: () => void;
  isCancelling: boolean;
  onClickCancel: () => Promise<void>;
}

export const SubscriptionCancelDialog: FC<Props> = (props) => {
  const { isOpen, close, isCancelling, onClickCancel } = props;

  useEffect(() => {
    if (!isOpen) return;
    logEvent("opened_cancel_subscription_dialog");
  }, [isOpen]);

  return (
    <DrawerDialog open={isOpen} onClose={() => !isCancelling && close()}>
      <DialogTitle textAlign="center">Cancel Subscription</DialogTitle>
      <DialogContent>
        Are sure you want to cancel your subscription?
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          disabled={isCancelling}
          onClick={close}
          startIcon={<ArrowBackIcon />}
        >
          Go back
        </Button>
        <Button
          size="large"
          color="error"
          disabled={isCancelling}
          onClick={onClickCancel}
          startIcon={<CancelIcon />}
        >
          Cancel Subscription
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
