import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Button,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Talent } from "@super-real/types";
import { FC } from "react";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { useMyUser } from "../../User/hooks/useMyUser";
import { ChatHeadMenuButton } from "./ChatHeadMenuButton";

interface Props {
  talent?: Talent;
}

export const ChatHeadView: FC<Props> = (props) => {
  const { talent } = props;
  const isTalentManagedByUser = useMyUser(
    (state) => talent?.id && state.user?.managedTalentIds?.includes(talent.id)
  );
  const isProfilePictureVisible = !!talent;

  return (
    <Stack position="sticky" top={0} left={0} right={0} zIndex={5}>
      <SChatHead direction="row" spacing={2}>
        <Stack>
          {!isProfilePictureVisible && (
            <Skeleton
              variant="circular"
              width={AVATAR_SIZE}
              height={AVATAR_SIZE}
            />
          )}
          {isProfilePictureVisible && (
            <Avatar
              src={talent.profilePictureUrl}
              sx={{
                bgcolor: "primary.main",
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
              }}
            />
          )}
        </Stack>
        <Stack flex={1} justifyContent="center">
          {!talent && (
            <>
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="30%" />
            </>
          )}
          {talent && (
            <>
              <Typography variant="ah3">{talent.name}</Typography>
              <Typography
                variant="caption"
                color="grey.300"
                fontWeight="medium"
              >
                {isTalentManagedByUser && (
                  <Button
                    component="a"
                    href={ENVIRONMENT.talentDomain}
                    type="text"
                    size="small"
                    color="secondary"
                    startIcon={<ArrowBackIcon />}
                    sx={{ fontSize: 12 }}
                  >
                    Back to Dashboard
                  </Button>
                )}
                {!isTalentManagedByUser && "Active now"}
              </Typography>
            </>
          )}
        </Stack>
        <Stack justifyContent="center">
          <ChatHeadMenuButton />
        </Stack>
      </SChatHead>
    </Stack>
  );
};

const SChatHead = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  backdropFilter: "blur(0.5px)",
  background:
    "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.95) 55%, rgba(0,0,0,0) 100%)",
}));

const AVATAR_SIZE = 96;
