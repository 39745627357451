import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { getMobileOs } from "../../Common/helpers/getMobileOs";
import { logEvent } from "../../Common/helpers/logEvent";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { APP_DOWNLOAD_DIALOG_REASON_RECORD } from "../consts/APP_DOWNLOAD_DIALOG_REASON_RECORD";
import { useApp } from "../hooks/useApp";
import { useAppDownloadDialog } from "../hooks/useAppDownloadDialog";

export const AppDownloadDialog: FC = () => {
  const { openedReason, close } = useAppDownloadDialog();
  const mobileOs = getMobileOs();
  const isAvailable = useApp((state) => state.app?.isAvailableInStore);

  useEffect(() => {
    if (!openedReason) return;
    const { openDialogEventName } =
      APP_DOWNLOAD_DIALOG_REASON_RECORD[openedReason];
    logEvent(openDialogEventName);
  }, [openedReason]);

  return (
    <DrawerDialog open={!!openedReason} disableRestoreFocus>
      <DialogContent>
        {openedReason && (
          <Stack spacing={3} alignItems="center">
            <Typography variant="ah3" textAlign="center">
              {APP_DOWNLOAD_DIALOG_REASON_RECORD[openedReason].label}
            </Typography>
            <Typography
              variant="ah2"
              textAlign="center"
              color="background.gradient"
            >
              Download
              <br />
              the app
            </Typography>
            <Stack direction="row" gap={1}>
              {(mobileOs === "IOS" || !mobileOs) && isAvailable?.venusIos && (
                <Link
                  href={ENVIRONMENT.venusStoreUrlForIos}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/download-on-the-app-store.svg"
                    alt="Download on the App Store"
                    height={52}
                  />
                </Link>
              )}
              {(mobileOs === "ANDROID" || !mobileOs) &&
                isAvailable?.venusAndroid && (
                  <Link
                    href={ENVIRONMENT.venusStoreUrlForAndroid}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/download-on-google-play.png"
                      alt="Get it on Google Play"
                      height={52}
                    />
                  </Link>
                )}
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
            Go back
          </Button>
          <Button
            component="a"
            target="_blank"
            href={ENVIRONMENT.venusStoreUrlForIos}
            size="large"
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            Download now
          </Button>
        </Stack>
      </DialogActions>
    </DrawerDialog>
  );
};
