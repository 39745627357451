import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { CheckoutPaymentSucceededDialog } from "../../Checkout/views/CheckoutPaymentSucceededDialog";
import { FirebaseAuthDialog } from "../../Firebase/views/FirebaseAuthDialog";
import { FirebaseAuthEmailLinkDialog } from "../../Firebase/views/FirebaseAuthEmailLinkDialog";
import { HomePage } from "../../Home/views/HomePage";
import { PaymentsPage } from "../../Payment/views/PaymentsPage";
import { TalentPage } from "../../Talent/views/TalentPage";
import { MainRedirectingPage } from "./MainRedirectingPage";

export const MainRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="redirecting" element={<MainRedirectingPage />} />
        <Route path=":talentTag">
          <Route index element={<TalentPage />} />
          <Route path="payments" element={<PaymentsPage />} />
        </Route>
      </Routes>
      <FirebaseAuthDialog />
      <FirebaseAuthEmailLinkDialog />
      <CheckoutPaymentSucceededDialog />
    </>
  );
};
