import { Payment, ProductPriceType } from "@super-real/types";

interface RecordValue {
  getPaymentHistoryLabel: (payment: Payment) => string;
}

export const PRODUCT_PRICE_TYPE_RECORD: Record<ProductPriceType, RecordValue> =
  {
    ONE_TIME: {
      getPaymentHistoryLabel: (payment) => `💎 ${payment.credits} added`,
    },
    SUBSCRIPTION: {
      getPaymentHistoryLabel: () => "Subscription",
    },
    APPLE_ONE_TIME: {
      getPaymentHistoryLabel: (payment) => `💎 ${payment.credits} added`,
    },
    GOOGLE_ONE_TIME: {
      getPaymentHistoryLabel: (payment) => `💎 ${payment.credits} added`,
    },
  };
