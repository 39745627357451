import CancelIcon from "@mui/icons-material/Cancel";
import { Avatar, Button, Skeleton, Stack, Typography } from "@mui/material";
import { Subscription } from "@super-real/types";
import { FC, useCallback, useEffect, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useTalent } from "../../Talent/hooks/useTalent";
import { cancelSubscriptionCallable } from "../callables/cancelSubscriptionCallable";
import { continueSubscriptionCallable } from "../callables/continueSubscriptionCallable";
import { SubscriptionCancelDialog } from "./SubscriptionCancelDialog";
import { SubscriptionStatusView } from "./SubscriptionStatusView";

interface Props {
  subscription: Subscription;
}

export const SubscriptionView: FC<Props> = (props) => {
  const { subscription } = props;
  const [isCancelling, setIsCancelling] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);
  const [isCancelDialogOpen, setCancelIsDialogOpen] = useState(false);
  const [isWaitingForUpdate, setIsWaitingForUpdate] = useState(false);
  const [talent] = useTalent(subscription.talentId);

  const onClickContinue = useCallback(async () => {
    try {
      setIsContinuing(true);
      await continueSubscriptionCallable({
        subscriptionId: subscription.id,
      });
      setIsWaitingForUpdate(true);
    } catch (error) {
      handleError(error);
    } finally {
      setIsContinuing(false);
    }
  }, [subscription.id]);

  const onClickCancel = useCallback(async () => {
    try {
      setIsCancelling(true);
      await cancelSubscriptionCallable({
        subscriptionId: subscription.id,
      });
      setIsWaitingForUpdate(true);
      setCancelIsDialogOpen(false);
    } catch (error) {
      handleError(error);
    } finally {
      setIsCancelling(false);
    }
  }, [subscription.id]);

  useEffect(() => {
    setIsWaitingForUpdate(false);
  }, [subscription]);

  const isLoading = isCancelling || isContinuing || isWaitingForUpdate;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="ah6">You AND</Typography>
            <Avatar
              src={talent?.profilePictureUrl}
              sx={{ width: 16, height: 16 }}
            />
            <Typography variant="ah6">
              {talent && talent.name}
              {!talent && <Skeleton width={70} />}
            </Typography>
          </Stack>
          <SubscriptionStatusView subscription={subscription} />
        </Stack>
        <Stack justifyContent="center">
          {subscription.status === "ACTIVE" && !subscription.cancelledAt && (
            <Button
              variant="contained"
              color="inherit"
              startIcon={<CancelIcon />}
              onClick={() => setCancelIsDialogOpen(true)}
              disabled={isLoading}
            >
              Cancel
            </Button>
          )}
          {subscription.status === "ACTIVE" && !!subscription.cancelledAt && (
            <Button
              variant="contained"
              color="info"
              startIcon={<CancelIcon />}
              onClick={onClickContinue}
              disabled={isLoading}
            >
              Continue
            </Button>
          )}
        </Stack>
      </Stack>
      <SubscriptionCancelDialog
        isOpen={isCancelDialogOpen}
        isCancelling={isCancelling}
        close={() => setCancelIsDialogOpen(false)}
        onClickCancel={onClickCancel}
      />
    </>
  );
};
