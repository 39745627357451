import { Stack, styled, Typography } from "@mui/material";
import { FC } from "react";
import { useApp } from "../../App/hooks/useApp";
import { CheckoutOneTimeView } from "../../Checkout/views/CheckoutOneTimeView";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

export const ChatMessageActionForCheckoutOneTimeView: FC = () => {
  const app = useApp((state) => state.app);
  const talent = useChatPageContext((state) => state.talent);
  const hasSubscription = useChatPageContext((state) => !!state.subscription);

  if (!app) return null;
  if (!talent) return null;

  return (
    <ChatMessageLayoutView
      senderType="TALENT"
      profilePictureUrl={talent.profilePictureUrl}
      fullWidth
      actions={<CheckoutOneTimeView talent={talent} />}
    >
      <Typography variant="body2">
        {talent.chatMessageForCheckout || app.defaultChatMessageForCheckout}
      </Typography>

      <Stack pt={2}>
        <Typography variant="ah6">My price list:</Typography>
        <Typography variant="body2">
          {!hasSubscription && (
            <>
              <Line>💎 {talent.creditsToSendMessage}</Line>
              <span> per Message</span>
              <br />
            </>
          )}
          <Line>💎 {talent.creditsToUnlockImage}</Line>
          <span> per Image</span>
          <br />
          <Line>💎 {talent.creditsToUnlockAudio}</Line>
          <span> per Voice</span>
        </Typography>
      </Stack>
    </ChatMessageLayoutView>
  );
};

const Line = styled("span")(() => ({
  whiteSpace: "nowrap",
  fontVariantNumeric: "tabular-nums",
  fontWeight: "bold",
}));
