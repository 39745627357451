import { Reaction } from "@super-real/types";
import { logEvent as logFirebaseAnalyticsEvent } from "firebase/analytics";
import { AppDownloadDialogReason } from "../../App/types/AppDownloadDialogReason";
import { analytics } from "../../Firebase/consts/FIREBASE";

export type EventName =
  | "sent_chat_message"
  | "sent_chat_message_suggestion"
  | "signed_up"
  | "signed_in"
  | "opened_checkout_dialog"
  | "opened_auth_dialog"
  | "opened_copy_url_dialog"
  | `opened_${Lowercase<AppDownloadDialogReason>}_dialog`
  | "clicked_auth_google"
  | "clicked_auth_apple"
  | "clicked_auth_email"
  | "opened_cancel_subscription_dialog"
  | `reacted_with_${Lowercase<Reaction>}`
  | "unlocked_image"
  | "unlocked_audio";

export function logEvent(eventName: EventName, itemId?: string | null) {
  if (!analytics) return;

  try {
    logFirebaseAnalyticsEvent(analytics, eventName, {
      ...(itemId && { item_id: itemId }),
    });
  } catch {
    // Do not throw an error!
  }
}
