import { keyframes, Stack, styled } from "@mui/material";
import { FC } from "react";

export const ChatTypingIndicator: FC = () => {
  return (
    <SStack direction="row" alignItems="center" display="flex" px={1} pt={1}>
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </SStack>
  );
};

const typingAnimation = keyframes`
  0% { transform: translateY(0px); }
  25% { transform: translateY(-6px); }
  50% { transform: translateY(0px); }
`;

const showingAnimation = keyframes`
  to { visibility: visible; }
`;

const SStack = styled(Stack)`
  visibility: hidden;
  animation: 0s linear 0.5s forwards ${showingAnimation}; // delay showing

  .dot {
    animation: ${typingAnimation} 0.8s infinite ease-in-out;
    background-color: ${(props) => props.theme.palette.primary.main};
    border-radius: 50%;
    height: 6px;
    width: 6px;
    margin-right: 4px;
  }

  .dot:nth-child(1) {
    animation-delay: 300ms;
  }

  .dot:nth-child(2) {
    animation-delay: 400ms;
  }

  .dot:nth-child(3) {
    animation-delay: 500ms;
  }
`;
