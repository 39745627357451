import { Stack, Typography } from "@mui/material";
import { Subscription } from "@super-real/types";
import { SubscriptionStatus } from "@super-real/types/types/SubscriptionStatus";
import { DateTime } from "luxon";
import { FC } from "react";
import { SUBSCRITPION_STATUS_RECORD } from "../consts/SUBSCRITPION_STATUS_RECORD";

interface Props {
  subscription?: Subscription;
}

export const SubscriptionStatusView: FC<Props> = (props) => {
  const { subscription } = props;
  const { color, label } = subscription
    ? SUBSCRITPION_STATUS_RECORD[subscription.status]
    : NO_SUBSCRIPTION;

  return (
    <Stack spacing={0.5}>
      <Stack>
        <Typography variant="ah3" color={color} alignSelf="flex-start">
          {label}
        </Typography>
      </Stack>
      {subscription?.status === "ACTIVE" && !subscription.cancelledAt && (
        <Typography variant="body2" fontWeight={800} fontSize={10}>
          Next payment on{" "}
          {DateTime.fromJSDate(
            subscription.currentPeriodEndedAt.toDate()
          ).toFormat("MMMM d")}
        </Typography>
      )}
      {subscription?.status === "ACTIVE" && !!subscription.cancelledAt && (
        <Typography variant="body2" fontWeight={800} fontSize={10}>
          Expires on{" "}
          {DateTime.fromJSDate(
            subscription.currentPeriodEndedAt.toDate()
          ).toFormat("MMMM d")}
        </Typography>
      )}
    </Stack>
  );
};

const NO_SUBSCRIPTION = {
  color: "grey.100",
  label: "Inactive",
} satisfies Partial<(typeof SUBSCRITPION_STATUS_RECORD)[SubscriptionStatus]>;
