import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useApp } from "../../App/hooks/useApp";
import { FirebaseAuthView } from "../../Firebase/views/FirebaseAuthView";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

export const ChatMessageActionForAuthView: FC = () => {
  const app = useApp((state) => state.app);
  const talent = useChatPageContext((state) => state.talent);

  if (!app) return null;
  if (!talent) return null;

  return (
    <ChatMessageLayoutView
      senderType="TALENT"
      profilePictureUrl={talent.profilePictureUrl}
      fullWidth
      actions={
        <Stack borderRadius="18px" mt={1} p={1.5} bgcolor="background.paper">
          <FirebaseAuthView size="small" />
        </Stack>
      }
    >
      <Typography variant="body2">
        {talent.chatMessageForAuth || app.defaultChatMessageForAuth}
      </Typography>
    </ChatMessageLayoutView>
  );
};
