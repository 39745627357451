import { CREDIT_REWARD_FOR_APP_USAGE } from "@super-real/types";
import { EventName } from "../../Common/helpers/logEvent";
import { AppDownloadDialogReason } from "../types/AppDownloadDialogReason";

interface RecordValue {
  openDialogEventName: EventName;
  label: string;
}

export const APP_DOWNLOAD_DIALOG_REASON_RECORD: Record<
  AppDownloadDialogReason,
  RecordValue
> = {
  CREDIT_REWARD_FOR_APP_USAGE: {
    openDialogEventName: "opened_credit_reward_for_app_usage_dialog",
    label: `Get another ${CREDIT_REWARD_FOR_APP_USAGE} free messages`,
  },
  UNLOCK_AUDIO: {
    openDialogEventName: "opened_unlock_audio_dialog",
    label: "To hear my voice",
  },
};
