import { FC, useEffect } from "react";
import { logEvent } from "../../Common/helpers/logEvent";
import { useChatMessageAction } from "../hooks/useChatMessageAction";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { ChatMessageActionForAuthView } from "./ChatMessageActionForAuthView";
import { ChatMessageActionForCheckoutOneTimeView } from "./ChatMessageActionForCheckoutOneTimeView";
import { ChatMessageActionForCheckoutSubscriptionView } from "./ChatMessageActionForCheckoutSubscriptionView";

export const ChatMessageActionView: FC = () => {
  const talent = useChatPageContext((state) => state.talent);
  const action = useChatMessageAction((state) => state.action);

  useEffect(() => {
    if (!action) return;
    switch (action) {
      case "AUTH":
        logEvent("opened_auth_dialog");
        break;
      case "CHECKOUT_ONE_TIME":
      case "CHECKOUT_SUBSCRIPTION":
        logEvent("opened_checkout_dialog");
        break;
    }
  }, [action]);

  if (!action) return null;
  if (!talent) return null;

  return (
    <>
      {action === "CHECKOUT_ONE_TIME" && (
        <ChatMessageActionForCheckoutOneTimeView />
      )}
      {action === "CHECKOUT_SUBSCRIPTION" && (
        <ChatMessageActionForCheckoutSubscriptionView />
      )}
      {action === "AUTH" && <ChatMessageActionForAuthView />}
    </>
  );
};
