import { useLocation } from "react-router-dom";
import { z } from "zod";

const SearchParams = z.object({
  customToken: z.string(),
});

export const useCustomTokenFromUrl = (): string | undefined => {
  const { search } = useLocation();
  const unsafeSearchParams = Object.fromEntries(new URLSearchParams(search));
  const result = SearchParams.safeParse(unsafeSearchParams);
  return result.success ? result.data.customToken : undefined;
};
