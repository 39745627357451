import {
  FirebaseFilter,
  isSubscription,
  Subscription,
} from "@super-real/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export const useSubscriptions = (
  filters: FirebaseFilter<Subscription>[] | null
) => {
  return useValidCollectionData(
    filters
      ? query(
          getCollectionRef("subscriptions"),
          ...filters.map((filter) => where(...filter))
        )
      : null,
    isSubscription
  );
};
