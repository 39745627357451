import { z } from "zod";
import { CheckoutPaymentCallbackParams } from "../types/CheckoutPaymentCallbackParams";

const Search = z.object({
  params: z.string(),
});

export const parseCheckoutPaymentCallbackParams = (
  search: string
): CheckoutPaymentCallbackParams | undefined => {
  try {
    const unsafeSearch = Object.fromEntries(new URLSearchParams(search));
    const searchResult = Search.safeParse(unsafeSearch);

    if (!searchResult.success) return;
    const searchParams = searchResult.data;
    const callbackParamsResult = CheckoutPaymentCallbackParams.safeParse(
      JSON.parse(decodeURIComponent(searchParams.params))
    );

    if (!callbackParamsResult.success) return;
    return callbackParamsResult.data;
  } catch {
    return;
  }
};
