import { useAppDownloadDialog } from "../../App/hooks/useAppDownloadDialog";
import { useMyUser } from "../../User/hooks/useMyUser";
import { shouldShowAppDownloadDialog } from "./shouldShowAppDownloadDialog";

export const handleNotEnoughCreditsAppDownloadDialog = () => {
  const { user } = useMyUser.getState();

  if (!user) return;
  if (user.hasReceivedCreditRewardForAppUsage) return;
  if (!shouldShowAppDownloadDialog()) return;

  useAppDownloadDialog.getState().open("CREDIT_REWARD_FOR_APP_USAGE");
};
