import { ChatMessage, Talent } from "@super-real/types";
import { Timestamp } from "firebase/firestore";
import { TEMPORARY_CHAT_ID } from "../consts/TEMPORARY_CHAT_ID";

/**
 * This messages are shown while creating the user and chat.
 */
export function generateInitialChatMessages(talent: Talent): ChatMessage[] {
  return talent.initialChatMessages.map<ChatMessage>((message, index) => ({
    ...message,
    chatId: TEMPORARY_CHAT_ID,
    senderType: "TALENT",
    senderId: talent.id,
    isInitial: true,
    createdAt: Timestamp.fromMillis(Date.now() + index),
  }));
}
