import { create } from "zustand";
import { useChatPageContext } from "./useChatPageContext";

type Action = "CHECKOUT_SUBSCRIPTION" | "CHECKOUT_ONE_TIME" | "AUTH";

interface State {
  action?: Action;
  show: (action: Action) => void;
  hide: () => void;
}

export const useChatMessageAction = create<State>((set) => ({
  show: (action) => {
    set({ action });
    setTimeout(() => {
      useChatPageContext
        .getState()
        .scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  },
  hide: () => set({ action: undefined }),
}));
