import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Alert, AlertProps, IconButton, styled } from "@mui/material";
import { FC } from "react";

interface Props extends AlertProps {
  open?: boolean;
  onClose: () => void;
}

export const CloseableAlert: FC<Props> = (props) => {
  const { open = true, onClose, ...alertProps } = props;

  if (!open) return null;

  return (
    <SAlert
      action={
        <IconButton color="inherit" size="medium" onClick={onClose}>
          <HighlightOffIcon fontSize="inherit" />
        </IconButton>
      }
      {...alertProps}
    />
  );
};

const SAlert = styled(Alert)(() => ({
  "& .MuiAlert-icon": {
    alignSelf: "center",
  },
}));
