import { useApp } from "../../App/hooks/useApp";
import { getMobileOs } from "../../Common/helpers/getMobileOs";

export const shouldShowAppDownloadDialog = (): boolean => {
  const { app } = useApp.getState();
  const mobileOs = getMobileOs();

  if (!app) return false;
  if (!mobileOs) return false;

  switch (mobileOs) {
    case "IOS":
      return app.isAvailableInStore.venusIos;
    case "ANDROID":
      return app.isAvailableInStore.venusAndroid;
  }
};
