import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { MainPage } from "../../Main/views/MainPage";

export const AppMaintenanceModeView: FC = () => {
  return (
    <MainPage>
      <Stack flex={1} alignItems="center" justifyContent="center" spacing={2}>
        <Typography fontSize={110}>⚡️</Typography>
        <Typography color="background.gradient" variant="ah3">
          Maintenance
        </Typography>
        <Typography variant="ah2" textAlign="center">
          Try again in few Minutes
        </Typography>
        <Typography variant="body2" textAlign="center" px={2}>
          Sorry for the inconvenience. We are updating few things to make your
          experience better. This will take a few minutes. Please stay on this
          page.
        </Typography>
      </Stack>
    </MainPage>
  );
};
