import { useRouter } from "next/router";
import { FC, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const MainRedirectingPage: FC = () => {
  const location = useLocation();
  const router = useRouter();
  const isRedirecting = useRef(false);
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("redirectUrl");

  // redirect back to client
  useEffect(() => {
    if (isRedirecting.current) return;
    if (!router) return;
    if (!redirectUrl) return;
    const decodedRedirectUrl = decodeURIComponent(redirectUrl);

    isRedirecting.current = true;
    void router.replace(decodedRedirectUrl).then(() => {
      isRedirecting.current = false;
    });
  }, [router, redirectUrl]);

  return null;
};
