import { zodResolver } from "@hookform/resolvers/zod";
import SendIcon from "@mui/icons-material/Send";
import { IconButton, Stack, styled } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { toTotalCredits } from "../../User/helpers/toTotalCredits";
import { useMyUser } from "../../User/hooks/useMyUser";
import { calculateCreditsForAction } from "../helpers/calculateCreditsForAction";
import { handleNotEnoughCredits } from "../helpers/handleNotEnoughCredits";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { useSendChatMessage } from "../hooks/useSendChatMessage";
import { ChatCreditInfoView } from "./ChatCreditInfoView";

const FormValues = z.object({
  text: z.string(),
});

export type FormValues = z.infer<typeof FormValues>;

export const ChatInputView: FC = () => {
  const talent = useChatPageContext((state) => state.talent);
  const [isCreditInfoOpen, setIsCreditInfoOpen] = useState(false);

  const { control, handleSubmit, setValue } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { text: "" },
  });

  const setTextField = useCallback(
    (text: string) => setValue("text", text),
    [setValue]
  );

  const { sendChatMessage, inputRef } = useSendChatMessage({ setTextField });

  const onSubmit = useCallback(
    (formValues: FormValues) => {
      void sendChatMessage(formValues.text);
    },
    [sendChatMessage]
  );

  return (
    <Stack position="sticky" bottom={0} left={0} right={0} zIndex={5}>
      <ChatCreditInfoView
        isOpen={isCreditInfoOpen}
        close={() => setIsCreditInfoOpen(false)}
      />
      <Stack
        position="relative"
        zIndex={1}
        bgcolor="grey.700"
        direction="row"
        alignItems="center"
        p={1}
        spacing={1}
      >
        <SIconButton
          sx={{
            borderWidth: isCreditInfoOpen ? 2 : 1,
            borderColor: isCreditInfoOpen ? "success.dark" : "grey.400",
          }}
          onClick={() => setIsCreditInfoOpen((prev) => !prev)}
        >
          💎
        </SIconButton>

        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          direction="row"
          bgcolor="background.default"
          borderRadius={100}
          flex={1}
        >
          <ControlledTextField
            name="text"
            control={control}
            inputRef={inputRef}
            sx={{
              "& fieldset": { border: "none" },
            }}
            onFocus={() => {
              if (!talent) return;
              if (!useMyUser.getState().user) return;

              const creditsForAction =
                calculateCreditsForAction("SEND_MESSAGE");
              const totalCredits = toTotalCredits(useMyUser.getState().user);

              if (totalCredits < creditsForAction) {
                handleNotEnoughCredits("SEND_MESSAGE");
                inputRef.current?.blur();
              }
            }}
            placeholder="Chat"
            autoComplete="off"
            disabled={!talent}
            fullWidth
          />
          <Stack justifyContent="center" pr={0.5}>
            <SIconButton type="submit">
              <SendIcon />
            </SIconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const SIconButton = styled(IconButton)(({ theme }) => ({
  width: 48,
  height: 48,
  borderColor: theme.palette.grey[400],
  borderStyle: "solid",
  backgroundColor: theme.palette.grey[500],

  "@media(hover: hover)": {
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));
