import { Box, Button, Skeleton, Stack } from "@mui/material";
import { Attachment } from "@super-real/types";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStorageFile } from "../../Common/hooks/useStorageFile";
import { useMainInit } from "../../Main/hooks/useMainInit";
import { calculateImageAttachmentAspectRatio } from "../helpers/calculateImageAttachmentAspectRatio";
import { useUnlockChatMessageAttachment } from "../hooks/useUnlockChatMessageAttachment";

interface Props {
  chatMessageId: string;
  attachment: Attachment & { type: "IMAGE" };
  attachmentIndex: number;
}

export const ChatAttachmentImageView: FC<Props> = (props) => {
  const { chatMessageId, attachment, attachmentIndex } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { url } = useStorageFile(attachment.filePath);
  const isAnonymous = useMainInit((state) => state.isAuthAnonymous);

  const { isUnlockingAttachment, unlockAttachment } =
    useUnlockChatMessageAttachment({
      chatMessageId,
      attachment,
      attachmentIndex,
    });

  const aspectRatio = calculateImageAttachmentAspectRatio(attachment);
  const isDisabled = isUnlockingAttachment;

  const isUnlocked = isAnonymous ? false : attachment.isUnlocked;
  const isSignUpCallToAction = isAnonymous && attachment.isUnlocked;

  return (
    <Stack overflow="hidden" borderRadius={3} m={-1} mb={1} position="relative">
      {!url && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="auto"
          sx={{ aspectRatio }}
        />
      )}

      {url && (
        <Box
          component="img"
          src={url}
          alt="Unknown"
          width="100%"
          height="auto"
          sx={{
            aspectRatio,
            filter: isUnlocked ? "none" : "blur(35px)",
            cursor: isUnlocked ? "pointer" : "default",
          }}
          onClick={() => {
            navigate(`${pathname}?filePath=${attachment.filePath}`, {
              replace: true,
            });
          }}
        />
      )}
      {!isUnlocked && (
        <Stack
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          padding={2}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            size="large"
            variant="contained"
            disabled={isDisabled}
            fullWidth
            onClick={unlockAttachment}
            sx={{
              whiteSpace: "normal",
              height: "auto",
              minHeight: 52,
              lineHeight: 1.5,
              borderRadius: 8,
            }}
          >
            {isSignUpCallToAction
              ? "Sign up and view for FREE"
              : "Click to unlock 👀"}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
