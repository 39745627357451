import { FirebaseFilter, isPayment, Payment } from "@super-real/types";
import { orderBy, query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export const usePayments = (filters?: FirebaseFilter<Payment>[]) => {
  return useValidCollectionData(
    query(
      getCollectionRef("payments"),
      ...(filters ? filters.map((filter) => where(...filter)) : []),
      orderBy("createdAt", "desc")
    ),
    isPayment
  );
};
