import ChatIcon from "@mui/icons-material/Chat";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Button, IconButton, Stack, styled, Typography } from "@mui/material";
import { PublicTalent } from "@super-real/types";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  publicTalent: PublicTalent;
}

export const HomeTile: FC<Props> = (props) => {
  const { publicTalent } = props;
  const navigate = useNavigate();
  const [firstName, ...rest] = publicTalent.name.split(" ");
  const lastName = rest.join(" ");

  return (
    <TileWrapper
      justifyContent="flex-end"
      url={publicTalent.profilePictureUrl}
      p={4}
      onClick={() => navigate(`/${publicTalent.tag}`)}
    >
      {publicTalent.instagramLink && (
        <Stack
          position="absolute"
          top={16}
          right={20}
          component="a"
          href={publicTalent.instagramLink}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <BadgeButton startIcon={<InstagramIcon />}>
            {publicTalent.instagramFollowerCount || "??"}k
          </BadgeButton>
        </Stack>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack fontSize="70%" overflow="hidden">
          <Headline>{firstName}</Headline>
          {lastName && <Headline mt={-2}>{lastName}</Headline>}
          <Subline>
            {publicTalent.subline || `${publicTalent.age} years old`}
          </Subline>
        </Stack>

        <SIconButton>
          <ChatIcon fontSize="large" />
        </SIconButton>
      </Stack>
    </TileWrapper>
  );
};

const TileWrapper = styled(Stack)<{ url?: string }>(({ url }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  aspectRatio: 0.85,
  borderRadius: 40,
  cursor: "pointer",
  transition: "all .2s ease-in-out",
  background: url && `url(${url})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",

  "@media (hover: hover)": {
    "&:hover": {
      transform: "scale(1.025)",
    },
  },
}));

const Headline = styled(Typography)(() => ({
  fontSize: "clamp(28px, 2em, 48px)",
  fontWeight: 800,
  textShadow: "5px 5px 5px rgba(0,0,0,.25)",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const Subline = styled(Typography)(() => ({
  fontSize: "clamp(14px, 2em, 20px)",
  fontWeight: 600,
  textShadow: "3px 3px 5px rgba(0,0,0,.15)",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const BadgeButton = styled(Button)(({ theme }) => ({
  padding: "4px 10px",
  borderRadius: 40,
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  fontWeight: 800,

  "@media (hover: hover)": {
    "&:hover": {
      background: theme.palette.common.white,
    },
  },
}));

const SIconButton = styled(IconButton)(() => ({
  padding: 20,
  background: "#311828",

  "@media (hover: hover)": {
    "&:hover": {
      background: "#2B1723",
    },
  },
}));
