import { Divider } from "@mui/material";
import { SubscriptionStatus } from "@super-real/types/types/SubscriptionStatus";
import { FC, Fragment } from "react";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { useSubscriptions } from "../hooks/useSubscriptions";
import { SubscriptionView } from "./SubscriptionView";

interface Props {
  userId: string;
}

export const SubscriptionsView: FC<Props> = (props) => {
  const { userId } = props;
  const [subscriptions, isLoading, error] = useSubscriptions([
    ["userId", "==", userId],
    ["status", "in", ["ACTIVE", "PAUSED"] satisfies SubscriptionStatus[]],
  ]);
  const isLoaded = !isLoading && !error;

  return (
    <>
      {error && <ErrorView error={error} />}
      {isLoading && <LoadingView />}
      {isLoaded && subscriptions.length > 0 && (
        <>
          {subscriptions.map((subscription, index) => (
            <Fragment key={subscription.id}>
              <SubscriptionView subscription={subscription} />
              {index < subscriptions.length - 1 && <Divider />}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};
