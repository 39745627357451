import { Attachment } from "@super-real/types";
import { useCallback, useState } from "react";
import { logEvent } from "../../Common/helpers/logEvent";
import { ATTACHMENT_TYPE_RECORD } from "../consts/ATTACHMENT_TYPE_RECORD";
import { useAudioPlayer } from "../views/ChatAttachmentAudioView/useAudioPlayer";
import { useAsyncChat } from "./useAsyncChat";
import { useExpensesQueue } from "./useExpensesQueue";
import { useLocalChatMessages } from "./useLocalChatMessages";

interface Params {
  chatMessageId: string;
  attachment: Attachment;
  attachmentIndex: number;
}

export const useUnlockChatMessageAttachment = (params: Params) => {
  const { chatMessageId, attachment, attachmentIndex } = params;
  const [isUnlockingAttachment, setIsUnlockingAttachment] = useState(false);

  const unlockAttachment = useCallback(async () => {
    setIsUnlockingAttachment(true);

    const action = ATTACHMENT_TYPE_RECORD[attachment.type].expenseAction;
    const chat = await useAsyncChat.getState().getChat();
    const { addExpense } = useExpensesQueue.getState();

    addExpense({
      action,
      chatId: chat.id,
      attachmentIndex,
      chatMessageId,
      onSuccess: () => {
        setIsUnlockingAttachment(false);
        logEvent(ATTACHMENT_TYPE_RECORD[attachment.type].unlockedEventName);
      },
      onError: () => {
        setIsUnlockingAttachment(false);
        useAudioPlayer.getState().stop();
        useLocalChatMessages.getState().reset();
      },
    });
  }, [attachment.type, attachmentIndex, chatMessageId]);

  return {
    isUnlockingAttachment,
    unlockAttachment,
  };
};
