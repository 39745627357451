import GavelIcon from "@mui/icons-material/Gavel";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupportIcon from "@mui/icons-material/Support";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useCheckoutDialog } from "../../Checkout/hooks/useCheckoutDialog";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { TERMS_AND_CONDITIONS_URL } from "../../Common/consts/TERMS_AND_CONDITIONS_URL";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useFirebaseAuthDialog } from "../../Firebase/hooks/useFirebaseAuthDialog";
import { useMainInit } from "../../Main/hooks/useMainInit";
import { useMyUser } from "../../User/hooks/useMyUser";
import { ChatHeadAdminDialog } from "./ChatHeadAdminDialog";

export const ChatHeadMenuButton: FC = () => {
  const { talentTag } = useParams<"talentTag">();
  const [isFirebaseAdminDialogOpen, setIsFirebaseAdminDialogOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAdmin = useMyUser((state) => state.user?.role === "ADMIN");
  const userId = useMyUser((state) => state.user?.id);
  const isAnonymous = useMainInit((state) => state.isAuthAnonymous);

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      setAnchorEl(null);
      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  }, []);

  const showFirebaseAdminDialog = useCallback(() => {
    setIsFirebaseAdminDialogOpen(true);
    setAnchorEl(null);
  }, []);

  const showFirebaseAuthDialog = useCallback(() => {
    useFirebaseAuthDialog.getState().open();
    setAnchorEl(null);
  }, []);

  const showCheckoutDialog = useCallback(() => {
    useCheckoutDialog.getState().open();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        size="large"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {(ENVIRONMENT.nodeEnv === "development" || isAdmin) && userId && (
          <MenuItem onClick={showFirebaseAdminDialog}>
            <ListItemIcon>
              <PersonIcon sx={{ color: "grey.100" }} />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
          </MenuItem>
        )}
        {isAnonymous && (
          <MenuItem onClick={showFirebaseAuthDialog}>
            <ListItemIcon>
              <LoginIcon sx={{ color: "grey.100" }} />
            </ListItemIcon>
            <ListItemText>Login</ListItemText>
          </MenuItem>
        )}
        {!isAnonymous && (
          <MenuItem onClick={showCheckoutDialog}>
            <ListItemIcon>
              <ShoppingCartIcon sx={{ color: "grey.100" }} />
            </ListItemIcon>
            <ListItemText>Buy Credits</ListItemText>
          </MenuItem>
        )}
        {!isAnonymous && talentTag && (
          <MenuItem to={`/${talentTag}/payments`} component={NavLink}>
            <ListItemIcon>
              <PaymentIcon sx={{ color: "grey.100" }} />
            </ListItemIcon>
            <ListItemText>Payments</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          component="a"
          onClick={() => setAnchorEl(null)}
          href={`mailto:support@super-real.co?subject=${encodeURIComponent(
            `User: ${userId || "Unknown"}`
          )}`}
        >
          <ListItemIcon>
            <SupportIcon sx={{ color: "grey.100" }} />
          </ListItemIcon>
          <ListItemText>Support</ListItemText>
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          onClick={() => setAnchorEl(null)}
          href={TERMS_AND_CONDITIONS_URL}
        >
          <ListItemIcon>
            <GavelIcon sx={{ color: "grey.100" }} />
          </ListItemIcon>
          <ListItemText>Terms & Conditions</ListItemText>
        </MenuItem>
        {!isAnonymous && (
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon color="error" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="error">Logout</Typography>
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <ChatHeadAdminDialog
        isOpen={isFirebaseAdminDialogOpen}
        close={() => setIsFirebaseAdminDialogOpen(false)}
      />
    </>
  );
};
