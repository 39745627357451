import { isUser, User } from "@super-real/types";
import { onSnapshot } from "firebase/firestore";
import toast from "react-hot-toast";
import { create } from "zustand";
import { useChatMessageAction } from "../../Chat/hooks/useChatMessageAction";
import { handleErrorWithoutToast } from "../../Common/helpers/handleError";
import { showConfetti } from "../../Common/helpers/showConfetti";
import { auth } from "../../Firebase/consts/FIREBASE";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { setupSentryUser } from "../../Setup/setupSentry";
import { requestCreditRewardForSignUpCallable } from "../callables/requestCreditRewardForSignUpCallable";

interface State {
  user?: User;
  isInitializing: boolean;
  subscribe: (userId: string) => void;
  unsubscribe?: () => void;
  applyCreditReward: (user?: User) => void;
}

export const useMyUser = create<State>((set, get) => ({
  isInitializing: true,
  subscribe: (userId) => {
    const unsubscribeFirestore = onSnapshot(
      getDocRef("users", userId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const user = getDataFromDocumentSnapshot(isUser, snapshot);

        setupSentryUser(user);
        get().applyCreditReward(user);

        set({
          user,
          ...(user && { isInitializing: false }),
        });
      },
      () => {
        set({ user: undefined, isInitializing: false });
      }
    );

    set({
      unsubscribe: () => {
        set({ user: undefined, isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
  applyCreditReward: (user?: User) => {
    if (!user) return;
    if (!auth.currentUser) return;
    if (auth.currentUser.isAnonymous) return;
    if (user.hasReceivedCreditRewardForSignUp) return;
    if (localStorage.getItem(IS_REQUESTING) === "true") return;

    localStorage.setItem(IS_REQUESTING, "true");
    void requestCreditRewardForSignUpCallable({})
      .then(({ creditRewardForSignUp }) => {
        showConfetti();
        useChatMessageAction.getState().hide();
        toast.success(
          `You received ${creditRewardForSignUp} credits for signing up!`
        );
      })
      .catch(handleErrorWithoutToast)
      .finally(() => {
        localStorage.setItem(IS_REQUESTING, "false");
      });
  },
}));

const IS_REQUESTING = "IS_REQUESTING_CREDIT_REWARD_FOR_SIGN_UP";
