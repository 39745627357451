import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { NavLink, useLocation } from "react-router-dom";

export const BackButton: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const parts = pathname.split("/").filter(Boolean);

  return (
    <Button
      component={NavLink}
      to={`/${parts.slice(0, -1).join("/")}`}
      size="large"
      sx={{ alignSelf: "flex-start" }}
      startIcon={<ArrowBackIcon />}
    >
      {children}
    </Button>
  );
};
