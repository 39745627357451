import { useMemo } from "react";

// Credits: https://github.com/atomantic/is-ua-webview
const RULES = [
  "WebView", // if it tells us it is a web,
  "Instagram", // instagrams webview supposedly has its name in the user agent
  "(iPhone|iPod|iPad)(?!.*Safari)", // iOS webview will be the same as safari but missing "Safari"
  "wv", // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  "Linux; U; Android", // old chrome android webview agent
];

const REG_EXP = new RegExp(`(${RULES.join("|")})`, "ig");

export const useIsWebView = () => {
  return useMemo(() => REG_EXP.test(navigator.userAgent), []);
};
