import { Product } from "@super-real/types";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";

export const toSubscriptionPriceLabel = (product: Product) => {
  if (product.priceType !== "SUBSCRIPTION") return "";

  const price = toLocaleMoney(product.price, { minimumFractionDigits: 0 });
  if (!price) return "";

  switch (product.billingPeriod.type) {
    case "DAYS":
      return `${price} for ${product.billingPeriod.value} ${
        product.billingPeriod.value === 1 ? "day" : "days"
      }`;
    case "WEEKS":
      return `${price} for ${product.billingPeriod.value} ${
        product.billingPeriod.value === 1 ? "week" : "weeks"
      }`;
    case "MONTHS":
      return `${price} for ${product.billingPeriod.value} ${
        product.billingPeriod.value === 1 ? "month" : "months"
      }`;
    default:
      return "";
  }
};
