import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { CREDIT_REWARD_FOR_SIGN_UP } from "@super-real/types";
import { FirebaseError } from "firebase/app";
import {
  AuthErrorCodes,
  getRedirectResult,
  OAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { FC, useEffect } from "react";
import {
  handleError,
  handleErrorWithoutToast,
} from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { updateUserCallable } from "../../User/callables/updateUserCallable";
import { auth } from "../consts/FIREBASE";
import { useFirebaseAuthDialog } from "../hooks/useFirebaseAuthDialog";
import { FirebaseAuthView } from "./FirebaseAuthView";

export const FirebaseAuthDialog: FC = () => {
  const { isOpen, close } = useFirebaseAuthDialog();

  useEffect(() => {
    if (!isOpen) return;
    logEvent("opened_auth_dialog");
  }, [isOpen]);

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (!result) return;
        if (result.operationType === "signIn") {
          logEvent("signed_in", result.providerId);
        }

        if (result.operationType === "link") {
          const { providerId, user } = result;
          logEvent("signed_up", providerId);
          void updateUserCallable({
            name: user.displayName || "",
            email: user.email || "",
          }).catch(handleErrorWithoutToast);
        }
      })
      .catch(async (error) => {
        if (!(error instanceof FirebaseError)) return;
        if (error.code !== AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE) return;

        try {
          const credential = OAuthProvider.credentialFromError(error);
          if (!credential) return;

          await signInWithCredential(auth, credential);
          logEvent("signed_in");
        } catch (error) {
          handleError(error);
        }
      });
  }, []);

  return (
    <DrawerDialog open={isOpen}>
      <DialogTitle textAlign="center">
        {CREDIT_REWARD_FOR_SIGN_UP} Free Messages
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography mb={1} fontSize={12} textAlign="center">
            Sign up or login to continue
          </Typography>
          <FirebaseAuthView />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Go back
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
