import { DateTime } from "luxon";
import { create } from "zustand";
import { handleError } from "../../Common/helpers/handleError";
import { toTotalCredits } from "../../User/helpers/toTotalCredits";
import { useMyUser } from "../../User/hooks/useMyUser";
import { createChatMessageCallable } from "../callables/createChatMessage";
import { unlockChatMessageAttachmentCallable } from "../callables/unlockChatMessageAttachmentCallable";
import { calculateCreditsForAction } from "../helpers/calculateCreditsForAction";
import { handleNotEnoughCredits } from "../helpers/handleNotEnoughCredits";

type Expense = {
  onSuccess: () => void;
  onError: (error: unknown) => void;
} & (
  | {
      action: "SEND_MESSAGE";
      chatId: string;
      localId: string;
      text: string;
    }
  | {
      action: "UNLOCK_IMAGE" | "UNLOCK_AUDIO";
      chatId: string;
      chatMessageId: string;
      attachmentIndex: number;
    }
);

interface State {
  isExecuting: boolean;
  readonly expenses: Expense[];
  readonly addExpense: (expense: Expense) => void;
  readonly startQueue: () => void;
  readonly executeNext: (totalUserCredits: number) => void;
  readonly execute: (expense: Expense) => Promise<number>;
  readonly reset: () => void;
}

export const useExpensesQueue = create<State>((set, get) => ({
  isExecuting: false,
  expenses: [],

  /**
   * Add expense.
   */
  addExpense: (expense) => {
    set({ expenses: [...get().expenses, expense] });
    get().startQueue();
  },

  /**
   * Start queue.
   */
  startQueue: () => {
    const { user } = useMyUser.getState();

    if (!user) return;

    const totalUserCredits = toTotalCredits(user);
    void get().executeNext(totalUserCredits);
  },

  /**
   * Execute next expense.
   */
  executeNext: async (totalUserCredits) => {
    if (get().isExecuting) return;

    const [expense] = get().expenses;
    if (!expense) return;

    const creditsForAction = calculateCreditsForAction(expense.action);

    if (totalUserCredits < creditsForAction) {
      set({ expenses: [] });
      handleNotEnoughCredits(expense.action);
      expense.onError(new Error("Not enough credits"));
      return;
    }

    const nextTotalUserCredits = await get().execute(expense);

    expense.onSuccess();
    set({ expenses: [...get().expenses.filter((e) => e !== expense)] });
    get().executeNext(nextTotalUserCredits);
  },

  /**
   * Execute expense.
   */
  execute: async (expense) => {
    get().isExecuting = true;

    try {
      switch (expense.action) {
        case "SEND_MESSAGE": {
          const { totalUserCredits } = await createChatMessageCallable({
            chatId: expense.chatId,
            localId: expense.localId,
            text: expense.text,
            timezone: DateTime.local().zoneName,
          });

          get().isExecuting = false;
          return totalUserCredits;
        }

        case "UNLOCK_AUDIO":
        case "UNLOCK_IMAGE": {
          const { totalUserCredits } =
            await unlockChatMessageAttachmentCallable({
              chatId: expense.chatId,
              chatMessageId: expense.chatMessageId,
              attachmentIndex: expense.attachmentIndex,
            });

          get().isExecuting = false;
          return totalUserCredits;
        }
      }
    } catch (error) {
      get().isExecuting = false;
      handleError(error);
      expense.onError(error);
      throw error;
    }
  },

  /**
   * Reset queue.
   */
  reset: () => {
    set({ expenses: [], isExecuting: false });
  },
}));
