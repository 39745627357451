import { Money } from "@super-real/types";

interface Params {
  minimumFractionDigits?: number;
}

export function toLocaleMoney(money?: Money, params?: Params) {
  return money?.amount.toLocaleString(undefined, {
    minimumFractionDigits: params?.minimumFractionDigits ?? 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: money.currency,
  });
}
