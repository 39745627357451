import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useApp } from "../../App/hooks/useApp";
import { toSubscriptionPriceLabel } from "../../Checkout/helpers/toSubscriptionPriceLabel";
import { useCheckoutDialog } from "../../Checkout/hooks/useCheckoutDialog";
import { CheckoutSubscribeButton } from "../../Checkout/views/CheckoutSubscribeButton";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

export const ChatMessageActionForCheckoutSubscriptionView: FC = () => {
  const app = useApp((state) => state.app);
  const talent = useChatPageContext((state) => state.talent);
  const product = useChatPageContext((state) =>
    state.products.find((p) => p.id === talent?.subscriptionProductId)
  );

  if (!app) return null;
  if (!talent) return null;
  if (!product) return null;

  return (
    <ChatMessageLayoutView
      senderType="TALENT"
      profilePictureUrl={talent.profilePictureUrl}
      actions={
        <Stack
          p={1.5}
          mt={1}
          bgcolor="background.paper"
          borderRadius="8px"
          border="1px solid"
          borderColor="grey.400"
          textAlign="center"
          spacing={1.5}
        >
          <Typography variant="ah6">Subscribe to Continue</Typography>
          <Stack flexDirection="row" justifyContent="center">
            <Typography
              variant="ah2"
              textAlign="center"
              color="background.gradient"
            >
              {toSubscriptionPriceLabel(product)}
            </Typography>
          </Stack>
          <Stack py={1} spacing={1}>
            <Typography variant="h3">💬 Unlimited text messages</Typography>
            {!!product.credits && !!talent.creditsToUnlockImage && (
              <Typography variant="h3">
                📸 Unlock up to{" "}
                {Math.floor(product.credits / talent.creditsToUnlockImage)}{" "}
                images
              </Typography>
            )}
          </Stack>
          <CheckoutSubscribeButton
            talent={talent}
            product={product}
            color="info"
            size="large"
            startIcon={<ShoppingCartIcon />}
          >
            Subscribe now
          </CheckoutSubscribeButton>
          <Button
            variant="text"
            sx={{ color: "grey.100" }}
            onClick={useCheckoutDialog.getState().open}
          >
            Or buy more 💎 to keep chatting
          </Button>
        </Stack>
      }
    >
      <Typography variant="body2">
        {talent.chatMessageForSubscription ||
          app.defaultChatMessageForSubscription}
      </Typography>
    </ChatMessageLayoutView>
  );
};
