import { Alert, Divider, Stack, Typography } from "@mui/material";
import { FC, Fragment } from "react";
import { PRODUCT_PRICE_TYPE_RECORD } from "../../Checkout/consts/PRODUCT_PRICE_TYPE_RECORD";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { usePayments } from "../hooks/usePayments";

interface Props {
  userId: string;
}

export const PaymentsView: FC<Props> = (props) => {
  const { userId } = props;
  const [payments, isLoading, error] = usePayments([["userId", "==", userId]]);
  const isLoaded = !isLoading && !error;

  return (
    <>
      {error && <ErrorView error={error} />}
      {isLoading && <LoadingView />}
      {isLoaded && payments.length === 0 && (
        <Stack p={2}>
          <Alert severity="info">No payments yet</Alert>
        </Stack>
      )}
      {isLoaded && payments.length > 0 && (
        <>
          <Stack p={2}>
            <Typography variant="ah5">Payment history</Typography>
          </Stack>
          <Stack
            bgcolor="grey.700"
            direction="row"
            justifyContent="space-between"
            p={2}
            spacing={2}
          >
            <Typography variant="ah3">Total</Typography>
            <Typography variant="ah3" color="info.main">
              {toLocaleMoney({
                amount: payments.reduce(
                  (result, payment) => result + payment.price.amount,
                  0
                ),
                currency: "USD",
              })}
            </Typography>
          </Stack>
          {payments.map((payment, index) => (
            <Fragment key={payment.id}>
              <Stack direction="row" spacing={2} p={2}>
                <Stack flex={1} spacing={0.5}>
                  <Typography variant="h3">
                    {PRODUCT_PRICE_TYPE_RECORD[
                      payment.productPriceType
                    ].getPaymentHistoryLabel(payment)}
                  </Typography>
                  <Typography variant="body2" fontSize={10}>
                    {toLocaleDateTimeString(payment.createdAt)}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h3">
                    {toLocaleMoney(payment.price)}
                  </Typography>
                </Stack>
              </Stack>
              {index < payments.length - 1 && <Divider />}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};
