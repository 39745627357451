import CheckIcon from "@mui/icons-material/Check";
import { Button, Dialog, Stack, styled, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useChatPageContext } from "../../Chat/hooks/useChatPageContext";
import { logPurchaseEvent } from "../../Common/helpers/logPurchaseEvent";
import { showConfetti } from "../../Common/helpers/showConfetti";
import { parseCheckoutPaymentCallbackParams } from "../helpers/parseCheckoutPaymentCallbackParams";
import { CheckoutPaymentCallbackParams } from "../types/CheckoutPaymentCallbackParams";

export const CheckoutPaymentSucceededDialog: FC = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [callbackParams, setCallbackParams] =
    useState<CheckoutPaymentCallbackParams>();
  const talent = useChatPageContext((state) => state.talent);

  useEffect(() => {
    const callbackParams = parseCheckoutPaymentCallbackParams(search);

    if (callbackParams?.paymentStatus !== "SUCCEEDED") return;
    setCallbackParams(callbackParams);
    showConfetti();

    logPurchaseEvent({
      stripePriceId: callbackParams.stripePriceId,
      currency: callbackParams.price.currency,
      value: callbackParams.price.amount,
    });

    navigate(pathname, { replace: true });
  }, [search, pathname, navigate]);

  const close = useCallback(() => setCallbackParams(undefined), []);

  return (
    <Dialog
      open={!!callbackParams}
      onClose={close}
      PaperProps={{
        sx: {
          m: 2,
          width: "100%",
          maxWidth: 450,
          background: "none",
        },
      }}
    >
      <Stack
        bgcolor="background.paper"
        borderRadius="8px"
        border="1px solid"
        borderColor="grey.400"
        p={2}
        spacing={1.5}
        alignItems="center"
        textAlign="center"
      >
        <GradientIcon p={2}>
          <CheckIcon fontSize="large" />
        </GradientIcon>

        <Stack pb={2}>
          {callbackParams?.priceType === "SUBSCRIPTION" && (
            <Typography variant="ah2">Subscription Active</Typography>
          )}
          {callbackParams?.priceType !== "SUBSCRIPTION" && (
            <Typography variant="ah2">Payment successful</Typography>
          )}

          <Typography variant="ah6">
            Enjoy chatting with {talent?.name ?? "this talent"}
          </Typography>
        </Stack>

        <Button
          size="large"
          variant="contained"
          color="info"
          onClick={close}
          fullWidth
        >
          Keep chatting
        </Button>
      </Stack>
    </Dialog>
  );
};

const GradientIcon = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.gradient,
  borderRadius: 40,
}));
