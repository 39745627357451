import InfoIcon from "@mui/icons-material/Info";
import MailIcon from "@mui/icons-material/Mail";
import {
  Button,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { TERMS_AND_CONDITIONS_URL } from "../../Common/consts/TERMS_AND_CONDITIONS_URL";
import { MainPage } from "../../Main/views/MainPage";
import { usePublicTalents } from "../hooks/usePublicTalents";
import { HomeTalentView } from "./HomeTalentView";

export const HomePageForSuperReal: FC = () => {
  const [publicTalents] = usePublicTalents([
    ["isVisibleRecord.HOME_PAGE_FOR_SUPER_REAL", "==", true],
  ]);

  return (
    <MainPage>
      <Stack justifyContent="center" flex={1} spacing={4}>
        <Stack>
          <Stack direction="row" justifyContent="center">
            <Typography
              variant="ah1"
              color="background.gradient"
              alignSelf="flex-start"
              textAlign="center"
              fontSize={100}
            >
              Super Real
            </Typography>
          </Stack>
          <Typography variant="ah3" textAlign="center" px={2}>
            Digitizing the world&apos;s most influential people
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={1}>
          {publicTalents.length === 0 && <HomeTalentView />}
          {publicTalents.map((publicTalent) => (
            <HomeTalentView key={publicTalent.id} publicTalent={publicTalent} />
          ))}
        </Stack>
        <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={1}>
          <SButton
            variant="outlined"
            size="large"
            href={ENVIRONMENT.talentDomain}
          >
            Make Your Own AI Twin
          </SButton>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Tooltip title="Contact" placement="top">
            <IconButton href="mailto:team@super-real.co">
              <MailIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Terms and Conditions" placement="top">
            <IconButton href={TERMS_AND_CONDITIONS_URL}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography textAlign="center" fontSize={10}>
          © Attention Is All You Need Ltd {DateTime.now().year}.
        </Typography>
      </Stack>
    </MainPage>
  );
};

const SButton = styled(Button)(({ theme }) => ({
  minWidth: 220,
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
}));
