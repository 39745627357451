import { signInAnonymously as signInAnonymouslyFirebase } from "firebase/auth";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { auth } from "../consts/FIREBASE";

export async function signInAnonymously() {
  // The emulator is syncronous, so we need to wait a bit to reproduce real production behavior.
  if (ENVIRONMENT.nodeEnv === "development") {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  return await signInAnonymouslyFirebase(auth);
}
