import { User } from "firebase/auth";
import { create } from "zustand";
import { isAnonymous } from "../../Common/helpers/isAnonymous";

interface MainInitState {
  error?: Error;
  isInitializing: boolean;
  isNewUser?: boolean;
  authUserId?: string;
  isAuthAnonymous: boolean;
  onAuthState: (
    authState: User | null | undefined,
    isInitializingAuthState: boolean
  ) => void;
}

export const useMainInit = create<MainInitState>((set, get) => ({
  error: undefined,
  isInitializing: true,
  isAuthAnonymous: true,
  onAuthState: (authState, isInitializingAuthState) => {
    set({
      authUserId: authState?.uid,
      isAuthAnonymous: isAnonymous(authState),
    });

    if (get().isNewUser !== undefined) return;
    if (isInitializingAuthState) return;

    // We mutate the state directly here, because we don't want to trigger a re-render
    get().isNewUser = !authState;
  },
}));
