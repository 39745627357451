import { Button, ButtonOwnProps } from "@mui/material";
import { Product, Talent } from "@super-real/types";
import { FC, PropsWithChildren, useCallback } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useCheckoutRedirect } from "../hooks/useCheckoutRedirect";

interface Props extends PropsWithChildren {
  talent: Talent;
  product: Product;
  color?: ButtonOwnProps["color"];
  size?: ButtonOwnProps["size"];
  startIcon?: ButtonOwnProps["startIcon"];
}

export const CheckoutSubscribeButton: FC<Props> = (props) => {
  const { color, size, startIcon, children, talent, product } = props;
  const { redirect, isRedirecting } = useCheckoutRedirect({ talent });

  const onClick = useCallback(async () => {
    try {
      await redirect(product);
    } catch (error) {
      handleError(error);
    }
  }, [product, redirect]);

  return (
    <Button
      variant="contained"
      size={size}
      color={color}
      onClick={onClick}
      disabled={isRedirecting}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );
};
