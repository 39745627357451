import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { HomePageForSuperReal } from "./HomePageForSuperReal";
import { HomePageForSuperSexy } from "./HomePageForSuperSexy";

const DOMAINS_FOR_SUPER_SEXY = [
  // "http://localhost:3000", // add this to see the supersexy.ai home page locally
  "https://super-sexy-messenger-stg.web.app",
  "https://supersexy.ai",
];

export const HomePage = DOMAINS_FOR_SUPER_SEXY.includes(ENVIRONMENT.domain)
  ? HomePageForSuperSexy
  : HomePageForSuperReal;
