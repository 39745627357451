import { Alert, Button, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useApp } from "../../App/hooks/useApp";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { MainContainer } from "./MainContainer";

const SECONDS_TO_RELOAD = 60;

export const MainMinVersionView: FC = () => {
  const [secondsLeft, setSecondsLeft] = useState(0);
  const isSupported = useApp((store) =>
    isVersionSupported(store.app?.minVersion.messenger, ENVIRONMENT.version)
  );

  useEffect(() => {
    if (isSupported) return;

    setSecondsLeft(SECONDS_TO_RELOAD);
    const interval = setInterval(() => {
      setSecondsLeft((secondsLeft) => {
        if (secondsLeft <= 1) {
          clearInterval(interval);
          window.location.reload();
        }

        return secondsLeft - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isSupported]);

  if (isSupported) return null;

  return (
    <Stack position="fixed" bottom={0} left={0} right={0} zIndex={5}>
      <MainContainer>
        <Alert
          severity="info"
          sx={{ mx: 1, mb: 10, alignItems: "center", opacity: 0.99 }}
          action={
            <Button
              variant="contained"
              color="info"
              onClick={() => window.location.reload()}
            >
              <Stack>
                <Typography variant="caption" fontWeight={700}>
                  Reload
                </Typography>
                <Typography variant="caption" fontSize={8} sx={{ mt: -0.5 }}>
                  {secondsLeft}s
                </Typography>
              </Stack>
            </Button>
          }
        >
          Great news! A new version of our app is now available.
        </Alert>
      </MainContainer>
    </Stack>
  );
};

export function isVersionSupported(minVersion = "", currentVersion = "") {
  const splittedMinVersion = minVersion.split(".");
  const splittedCurrentVersion = currentVersion.split(".");

  for (let i = 0; i < splittedMinVersion.length; i++) {
    const minVersionNumber = parseInt(splittedMinVersion[i]) || 0;
    const currentVersionNumber = parseInt(splittedCurrentVersion[i]) || 0;

    if (currentVersionNumber !== minVersionNumber) {
      return currentVersionNumber >= minVersionNumber;
    }
  }

  return true;
}
