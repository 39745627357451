import {
  FirebaseFilter,
  isPublicTalent,
  PublicTalent,
} from "@super-real/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export const usePublicTalents = (filters: FirebaseFilter<PublicTalent>[]) => {
  const result = useValidCollectionData(
    query(
      getCollectionRef("publicTalents"),
      ...(filters ? filters.map((filter) => where(...filter)) : [])
    ),
    isPublicTalent
  );

  result[0].sort((a, b) => a.order - b.order);

  return result;
};
