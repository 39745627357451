import { Product, Talent } from "@super-real/types";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation } from "react-router-dom";
import { useChatPageContext } from "../../Chat/hooks/useChatPageContext";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useFirebaseAuthDialog } from "../../Firebase/hooks/useFirebaseAuthDialog";
import { createCheckoutSessionCallable } from "../callables/createCheckoutSessionCallable";
import { generateCheckoutPaymentCallbackUrl } from "../helpers/generateCheckoutPaymentCallbackUrl";

interface Params {
  talent: Talent;
}

export const useCheckoutRedirect = (params: Params) => {
  const { talent } = params;
  const { pathname } = useLocation();
  const router = useRouter();
  const [authState] = useAuthState(auth);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const subscription = useChatPageContext((state) => state.subscription);

  const redirect = useCallback(
    async (product: Product) => {
      try {
        if (
          product.priceType !== "SUBSCRIPTION" &&
          product.priceType !== "ONE_TIME"
        )
          return;
        if (product.priceType === "SUBSCRIPTION" && subscription) return;
        if (isRedirecting) return;
        if (!authState || authState.isAnonymous) {
          useFirebaseAuthDialog.getState().open();
          return;
        }

        setIsRedirecting(true);

        const toltReferral =
          "tolt_referral" in window && typeof window.tolt_referral === "string"
            ? window.tolt_referral
            : undefined;

        const { sessionUrl } = await createCheckoutSessionCallable({
          talentId: talent.id,
          productId: product.id,
          ...(toltReferral && { toltReferral }),
          successUrl: generateCheckoutPaymentCallbackUrl({
            paymentStatus: "SUCCEEDED",
            product,
            pathname,
          }),
          cancelUrl: generateCheckoutPaymentCallbackUrl({
            paymentStatus: "CANCELLED",
            product,
            pathname,
          }),
        });

        // for other domains: redirect to "super-real/redirecting"-page first, then redirect to stripe from there
        if (ENVIRONMENT.domain !== ENVIRONMENT.stripeRedirectDomain) {
          const encodedSessionUrl = encodeURIComponent(sessionUrl);
          await router.push(
            `${ENVIRONMENT.stripeRedirectDomain}/redirecting?redirectUrl=${encodedSessionUrl}`
          );
          return;
        }

        await router.push(sessionUrl);
      } catch (error) {
        handleError(error);
        setIsRedirecting(false);
      }
    },
    [subscription, isRedirecting, authState, talent.id, pathname, router]
  );

  return { redirect, isRedirecting };
};
