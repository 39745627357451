type MobileOs = "IOS" | "ANDROID";

export function getMobileOs(): MobileOs | undefined {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/ipad|iphone|ipod/g.test(userAgent)) {
    return "IOS";
  }

  if (/android/g.test(userAgent)) {
    return "ANDROID";
  }

  return undefined;
}
