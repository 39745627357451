import { Money, Product } from "@super-real/types";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { CheckoutPaymentCallbackParams } from "../types/CheckoutPaymentCallbackParams";

interface Props {
  paymentStatus: CheckoutPaymentCallbackParams["paymentStatus"];
  product: Product & { stripePriceId: string; price: Money };
  pathname: string;
}

export const generateCheckoutPaymentCallbackUrl = (props: Props) => {
  const { product, pathname, paymentStatus } = props;

  const params: CheckoutPaymentCallbackParams = {
    price: product.price,
    priceType: product.priceType,
    stripePriceId: product.stripePriceId,
    credits: product.priceType === "ONE_TIME" ? product.credits : 0,
    paymentStatus,
  };

  const domain = ENVIRONMENT.domain;
  const stripeRedirectDomain = ENVIRONMENT.stripeRedirectDomain;
  const paramsString = JSON.stringify(params);
  const encodedParamsString = encodeURIComponent(paramsString);
  const url = `${domain}${pathname}?params=${encodedParamsString}`;

  if (domain !== stripeRedirectDomain) {
    const redirectUrl = encodeURIComponent(url);
    return `${stripeRedirectDomain}/redirecting?redirectUrl=${redirectUrl}`;
  }

  return url;
};
