import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StorageImage } from "../../Common/views/StorageImage";
import { MainContainer } from "../../Main/views/MainContainer";

export const ChatImageDialog: FC = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const close = useCallback(
    () => navigate(pathname, { replace: true }),
    [navigate, pathname]
  );

  const filePath = useMemo(() => {
    return new URLSearchParams(search).get("filePath");
  }, [search]);

  if (!filePath) return null;

  return (
    <Dialog
      open
      fullScreen
      onClose={close}
      PaperProps={{
        sx: {
          backgroundImage: "unset",
          bgcolor: "common.black",
        },
      }}
    >
      <MainContainer sx={{ height: "100%" }}>
        <Stack direction="row" flex={1}>
          <Button
            sx={{ my: 1 }}
            size="large"
            onClick={close}
            startIcon={
              <Stack justifyContent="center">
                <ArrowBackIcon fontSize="large" />
              </Stack>
            }
          >
            <Typography variant="ah3">Back</Typography>
          </Button>
        </Stack>

        <StorageImage filePath={filePath} />

        <Stack flex={1} />
      </MainContainer>
    </Dialog>
  );
};
