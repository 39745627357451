import { IconButton, Stack, styled } from "@mui/material";
import { ChatMessage, Reaction, REACTIONS } from "@super-real/types";
import { FC, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { reactToChatMessageCallable } from "../callables/reactToChatMessageCallable";
import { toReactionEmoji } from "../helpers/toReactionEmoji";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { ChatMessageReactionFeedbackDialog } from "./ChatMessageReactionFeedbackDialog";

interface Props {
  chatMessage: ChatMessage;
}

export const ChatMessageReactionView: FC<Props> = (props) => {
  const { chatMessage } = props;
  const chatId = useChatPageContext((state) => state.chat?.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const onClickReact = useCallback(
    async (reaction: Reaction) => {
      try {
        if (!chatId) return;
        if (reaction === "THUMBS_DOWN") {
          setIsFeedbackDialogOpen(true);
        }

        setIsSubmitting(true);

        await reactToChatMessageCallable({
          chatId,
          chatMessageId: chatMessage.id,
          reaction,
        });

        switch (reaction) {
          case "THUMBS_UP":
            logEvent("reacted_with_thumbs_up");
            break;
          case "THUMBS_DOWN":
            logEvent("reacted_with_thumbs_down");
            break;
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [chatId, chatMessage]
  );

  const isDisabled = !chatId || isSubmitting;

  return (
    <Stack
      direction="row"
      mt={1}
      spacing={0.5}
      justifyContent="flex-end"
      height={BUTTON_SIZE}
    >
      {!chatMessage.reaction &&
        REACTIONS.map((reaction) => (
          <SIconButton
            key={reaction}
            disabled={isDisabled}
            onClick={() => onClickReact(reaction)}
          >
            {toReactionEmoji(reaction)}
          </SIconButton>
        ))}
      <ChatMessageReactionFeedbackDialog
        chatId={chatId}
        chatMessageId={chatMessage.id}
        open={isFeedbackDialogOpen}
        close={() => setIsFeedbackDialogOpen(false)}
      />
    </Stack>
  );
};

const BUTTON_SIZE = 44;

const SIconButton = styled(IconButton)(({ theme }) => ({
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[400],
  fontSize: theme.typography.body1.fontSize,
  backgroundColor: theme.palette.grey[500],
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.grey[500],
    opacity: 0.7,
  },
}));
