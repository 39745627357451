import { Money, ProductPriceType } from "@super-real/types";
import { z } from "zod";

export const CheckoutPaymentCallbackParams = z.object({
  paymentStatus: z.enum(["SUCCEEDED", "CANCELLED"]),
  stripePriceId: z.string(),
  price: Money,
  priceType: ProductPriceType,
  credits: z.number(),
});

export type CheckoutPaymentCallbackParams = z.infer<
  typeof CheckoutPaymentCallbackParams
>;
