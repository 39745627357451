import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { BackButton } from "../../Common/views/BackButton";
import { MainPage } from "../../Main/views/MainPage";
import { useMyUser } from "../../User/hooks/useMyUser";
import { PaymentsView } from "./PaymentsView";
import { SubscriptionsView } from "./SubscriptionsView";

export const PaymentsPage: FC = () => {
  const userId = useMyUser((state) => state.user?.id);

  if (!userId) return;

  return (
    <MainPage>
      <Stack bgcolor="grey.700" p={2} spacing={2}>
        <BackButton>Back to chat</BackButton>
        <Typography variant="ah2">Subscriptions and payments</Typography>
        <SubscriptionsView userId={userId} />
      </Stack>
      <PaymentsView userId={userId} />
    </MainPage>
  );
};
