import { Button, Stack } from "@mui/material";
import { Attachment } from "@super-real/types";
import { FC, useCallback, useEffect } from "react";
import { useAppDownloadDialog } from "../../../App/hooks/useAppDownloadDialog";
import { handleError } from "../../../Common/helpers/handleError";
import { shouldShowAppDownloadDialog } from "../../helpers/shouldShowAppDownloadDialog";
import { useChatPageContext } from "../../hooks/useChatPageContext";
import { useUnlockChatMessageAttachment } from "../../hooks/useUnlockChatMessageAttachment";
import { AUDIO_PLAYER_SIZE } from "./AUDIO_PLAYER";
import { AudioPlayButton } from "./AudioPlayButton";
import { AudioSkeleton } from "./AudioSkeleton";
import { toAttachmentId } from "./toAttachmentId";
import { useAudioPlayer } from "./useAudioPlayer";

interface Props {
  chatMessageId: string;
  attachment: Attachment & { type: "AUDIO" };
  attachmentIndex: number;
}

export const ChatAttachmentAudioView: FC<Props> = (props) => {
  const { chatMessageId, attachment, attachmentIndex } = props;
  const { filePath, isUnlocked } = attachment;
  const hasChat = useChatPageContext((state) => !!state.chat);
  const attachmentId = toAttachmentId(chatMessageId, attachmentIndex);
  const isInitialized = useAudioPlayer(
    (state) => state.isInitializedRecord[attachmentId]
  );

  useEffect(() => {
    async function startEffect() {
      try {
        if (!filePath) return;
        if (!isUnlocked) return;
        await useAudioPlayer.getState().downloadUrl(attachmentId, filePath);
        await useAudioPlayer.getState().initPlayer(attachmentId);
        if (attachmentId === useAudioPlayer.getState().startedAttachmentId) {
          await useAudioPlayer.getState().play(attachmentId);
        }
      } catch (error) {
        handleError(error);
      }
    }

    void startEffect();

    return () => {
      useAudioPlayer.getState().destroy(attachmentId);
    };
  }, [attachmentId, filePath, isUnlocked]);

  const { isUnlockingAttachment, unlockAttachment } =
    useUnlockChatMessageAttachment({
      chatMessageId,
      attachment,
      attachmentIndex,
    });

  const onClickUnlockAttachment = useCallback(() => {
    if (shouldShowAppDownloadDialog()) {
      useAudioPlayer.getState().stop();
      useAppDownloadDialog.getState().open("UNLOCK_AUDIO");
    } else {
      void unlockAttachment();
    }
  }, [unlockAttachment]);

  const isDisabled = isUnlockingAttachment || !hasChat;
  const isUnlockButtonVisible =
    !attachment.isUnlocked && !isUnlockingAttachment;

  return (
    <Stack direction="row" spacing={2} flex={1} alignItems="center">
      <AudioPlayButton
        attachmentId={attachmentId}
        filePath={filePath}
        isUnlocked={isUnlocked}
        unlockAttachment={onClickUnlockAttachment}
      />
      {isUnlockButtonVisible && (
        <Button
          size="large"
          variant="contained"
          onClick={onClickUnlockAttachment}
          disabled={isDisabled}
          fullWidth
          sx={{
            height: AUDIO_PLAYER_SIZE,
            borderRadius: AUDIO_PLAYER_SIZE / 2,
            overflow: "hidden",
            px: 1,
          }}
        >
          Click to unlock 🔊
        </Button>
      )}
      {!isUnlockButtonVisible && (
        <Stack flex={1} justifyContent="center" position="relative">
          {!isInitialized && (
            <AudioSkeleton sx={{ position: "absolute", left: 0, right: 0 }} />
          )}
          <div
            style={{ visibility: isInitialized ? "visible" : "hidden" }}
            id={attachmentId}
          />
        </Stack>
      )}
    </Stack>
  );
};
