import { Stack, styled, Typography } from "@mui/material";
import { Attachment, ChatMessage } from "@super-real/types";
import { FC, Fragment, useMemo } from "react";
import { useApp } from "../../App/hooks/useApp";
import { calculateImageAttachmentAspectRatio } from "../helpers/calculateImageAttachmentAspectRatio";
import { toReactionEmoji } from "../helpers/toReactionEmoji";
import { useChatMessageAction } from "../hooks/useChatMessageAction";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { ChatAttachmentAudioView } from "./ChatAttachmentAudioView";
import { ChatAttachmentImageView } from "./ChatAttachmentImageView";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";
import { ChatMessageReactionView } from "./ChatMessageReactionView";

interface Props {
  chatMessage: ChatMessage;
  isLastMessage?: boolean;
}

export const ChatMessageView: FC<Props> = (props) => {
  const { chatMessage, isLastMessage } = props;
  const profilePictureUrl = useChatPageContext(
    (state) => state.talent?.profilePictureUrl
  );
  const subscription = useChatPageContext((state) => state.subscription);
  const emptyChatMessageText = useApp(
    (state) => state.app?.emptyChatMessageText
  );
  const isChatMessageActionVisible = useChatMessageAction(
    (state) => !!state.action
  );

  const isChatMessageTextVisible =
    !!subscription ||
    !chatMessage.attachments?.map((a) => a.type).includes("AUDIO");

  const maxWidth = useMemo(() => {
    const imageAttachment = chatMessage.attachments?.find(
      ({ type }) => type === "IMAGE"
    ) as Attachment & { type: "IMAGE" };

    const aspectRatio = calculateImageAttachmentAspectRatio(imageAttachment);
    if (!aspectRatio) return undefined;

    const min = 50;
    const max = 75;
    return `${Math.pow(aspectRatio, 2) * (max - min) + min}%`;
  }, [chatMessage]);

  return (
    <ChatMessageLayoutView
      senderType={chatMessage.senderType}
      profilePictureUrl={profilePictureUrl}
      maxWidth={maxWidth}
      fullWidth={!!chatMessage.attachments?.length}
      actions={
        isLastMessage &&
        chatMessage.senderType === "TALENT" &&
        !isChatMessageActionVisible &&
        !chatMessage.isInitial && (
          <ChatMessageReactionView chatMessage={chatMessage} />
        )
      }
    >
      {chatMessage.attachments?.map((attachment, index) => (
        <Fragment key={index}>
          {attachment.type === "IMAGE" && (
            <ChatAttachmentImageView
              chatMessageId={chatMessage.id}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
          {attachment.type === "AUDIO" && (
            <ChatAttachmentAudioView
              chatMessageId={chatMessage.id}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
        </Fragment>
      ))}
      {isChatMessageTextVisible && (
        <Typography variant="body2">
          {chatMessage.text || emptyChatMessageText}
        </Typography>
      )}
      {!!chatMessage.reaction && (
        <SReactionView>{toReactionEmoji(chatMessage.reaction)}</SReactionView>
      )}
    </ChatMessageLayoutView>
  );
};

const REACTION_SIZE = 24;

const SReactionView = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: REACTION_SIZE / -3,
  right: theme.spacing(1),
  width: REACTION_SIZE,
  height: REACTION_SIZE,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.grey[500],
  fontSize: 12,
  borderRadius: REACTION_SIZE / 2,
  alignItems: "center",
  justifyContent: "center",
}));
