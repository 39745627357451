import { AttachmentType } from "@super-real/types";
import { EventName } from "../../Common/helpers/logEvent";

interface RecordValue {
  expenseAction: "UNLOCK_IMAGE" | "UNLOCK_AUDIO";
  unlockedEventName: EventName;
}

export const ATTACHMENT_TYPE_RECORD: Record<AttachmentType, RecordValue> = {
  IMAGE: {
    expenseAction: "UNLOCK_IMAGE",
    unlockedEventName: "unlocked_image",
  },
  AUDIO: {
    expenseAction: "UNLOCK_AUDIO",
    unlockedEventName: "unlocked_audio",
  },
};
