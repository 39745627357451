import { SubscriptionStatus } from "@super-real/types/types/SubscriptionStatus";

interface RecordValue {
  label: string;
  color: string;
}

export const SUBSCRITPION_STATUS_RECORD: Record<
  SubscriptionStatus,
  RecordValue
> = {
  ACTIVE: {
    label: "Active",
    color: "background.gradient",
  },
  PAUSED: {
    label: "Paused",
    color: "grey.100",
  },
  ENDED: {
    label: "Ended",
    color: "grey.100",
  },
};
