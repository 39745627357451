import { create } from "zustand";
import { AppDownloadDialogReason } from "../types/AppDownloadDialogReason";

interface State {
  openedReason?: AppDownloadDialogReason;
  open: (reason: AppDownloadDialogReason) => void;
  close: () => void;
}

export const useAppDownloadDialog = create<State>((set) => ({
  open: (reason) => set({ openedReason: reason }),
  close: () => set({ openedReason: undefined }),
}));
