import { Chat, Product, Subscription, Talent } from "@super-real/types";
import { RefObject } from "react";
import { create } from "zustand";
import { useAsyncChat } from "./useAsyncChat";

interface Context {
  talent?: Talent;
  chat?: Chat;
  subscription?: Subscription;
  products: Product[];
  isLoadingSubscription: boolean;
}

type State = Context & {
  scrollRef: RefObject<HTMLDivElement>;
  update: (nextContext: Partial<Context>) => void;
  reset: () => void;
};

export const useChatPageContext = create<State>((set) => ({
  products: [],
  isLoadingSubscription: true,
  profilePicture: {
    isLoading: true,
  },
  scrollRef: { current: null },
  update: (nextContext) => {
    set(nextContext);
    useAsyncChat.getState().setChat(nextContext.chat);
  },
  reset: () => {
    set({
      talent: undefined,
      chat: undefined,
      subscription: undefined,
      isLoadingSubscription: true,
    });
  },
}));
