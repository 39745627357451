import { signInWithCustomToken } from "firebase/auth";
import { FC, useLayoutEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../App/hooks/useApp";
import { auth } from "../../Firebase/consts/FIREBASE";
import { signInAnonymously } from "../../Firebase/helpers/signInAnonymously";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useCustomTokenFromUrl } from "../hooks/useCustomTokenFromUrl";
import { useMainInit } from "../hooks/useMainInit";

export const MainInitView: FC = () => {
  const navigate = useNavigate();
  const customToken = useCustomTokenFromUrl();
  const [authState, isInitializingAuthState] = useAuthState(auth);
  const isInitializingApp = useApp((state) => state.isInitializing);
  const userId = authState?.uid;

  /**
   * On auth state change.
   */
  useLayoutEffect(() => {
    useMainInit.getState().onAuthState(authState, isInitializingAuthState);
  }, [authState, isInitializingAuthState]);

  /**
   * Sign in anonymously or with custom token.
   */
  useLayoutEffect(() => {
    if (isInitializingAuthState) return;

    if (customToken) {
      signInWithCustomToken(auth, customToken)
        .then(() => navigate(window.location.pathname, { replace: true }))
        .catch((error: Error) => useMainInit.setState({ error }));
      return;
    }

    if (userId) return;

    signInAnonymously().catch((error: Error) => {
      useMainInit.setState({ error });
    });
  }, [userId, isInitializingAuthState, customToken, navigate]);

  /**
   * Dependencies: none
   */
  useLayoutEffect(() => {
    useApp.getState().subscribe("default");

    return () => {
      useApp.getState().unsubscribe?.();
    };
  }, []);

  /**
   * Dependencies: userId
   */
  useLayoutEffect(() => {
    if (userId) {
      useMyUser.getState().subscribe(userId);
    }

    return () => {
      useMyUser.getState().unsubscribe?.();
    };
  }, [userId]);

  // TODO: Remove `!userId` as soon as it's not needed for test groups anymore.
  const isInitializing =
    isInitializingAuthState || isInitializingApp || !userId;

  /**
   * Dependencies: isInitializing
   */
  useLayoutEffect(() => {
    useMainInit.setState({ isInitializing });
  }, [isInitializing]);

  return null;
};
