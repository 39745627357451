import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, Slide, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { toSubscriptionPriceLabel } from "../../Checkout/helpers/toSubscriptionPriceLabel";
import { CheckoutSubscribeButton } from "../../Checkout/views/CheckoutSubscribeButton";
import { LoadingView } from "../../Common/views/LoadingView";
import { SubscriptionStatusView } from "../../Payment/views/SubscriptionStatusView";
import { TalentCreditsPricingView } from "../../Talent/views/TalentCreditsPricingView";
import { useMyUserTotalCredits } from "../../User/hooks/useMyUserTotalCredits";
import { handleNotEnoughCredits } from "../helpers/handleNotEnoughCredits";
import { useChatPageContext } from "../hooks/useChatPageContext";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const ChatCreditInfoView: FC<Props> = (props) => {
  const { isOpen, close } = props;
  const totalCredits = useMyUserTotalCredits();
  const talent = useChatPageContext((state) => state.talent);
  const subscription = useChatPageContext((state) => state.subscription);
  const product = useChatPageContext((state) =>
    state.products.find((p) => p.id === talent?.subscriptionProductId)
  );
  const isLoadingSubscription = useChatPageContext(
    (state) => state.isLoadingSubscription
  );

  const onClickBuyMore = useCallback(() => {
    if (!talent) return;
    handleNotEnoughCredits("UNLOCK_IMAGE");
    close();
  }, [talent, close]);

  if (!talent) return null;

  return (
    <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
      <div>
        <Stack bgcolor="grey.400" p={2} spacing={2}>
          {isLoadingSubscription && <LoadingView />}
          {!isLoadingSubscription && (
            <>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Stack spacing={0.5} overflow="hidden">
                  {talent.subscriptionProductId && (
                    <>
                      <Stack spacing={0.5}>
                        <Typography
                          variant="h5"
                          color="common.white"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          display="inline-block"
                        >
                          Your subscription with {talent.name || "this talent"}
                        </Typography>
                      </Stack>
                      <SubscriptionStatusView subscription={subscription} />
                    </>
                  )}
                </Stack>
                <Stack spacing={0.5}>
                  <Typography
                    variant="h5"
                    color="common.white"
                    textAlign="right"
                    whiteSpace="nowrap"
                  >
                    Available Credits
                  </Typography>
                  <Typography
                    variant="ah3"
                    textAlign="right"
                    whiteSpace="nowrap"
                  >
                    💎 {totalCredits}
                  </Typography>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                {talent.subscriptionProductId && !subscription && product && (
                  <CheckoutSubscribeButton
                    product={product}
                    talent={talent}
                    color="secondary"
                    size="large"
                  >
                    Subscribe - {toSubscriptionPriceLabel(product)}
                  </CheckoutSubscribeButton>
                )}
                <Button
                  variant="contained"
                  size="large"
                  color="info"
                  startIcon={<ShoppingCartIcon />}
                  onClick={onClickBuyMore}
                >
                  Buy more 💎
                </Button>
              </Stack>
            </>
          )}
        </Stack>
        <Stack bgcolor="grey.700" px={2} pt={2} pb={1}>
          <TalentCreditsPricingView talent={talent} bgcolor="grey.400" />
        </Stack>
      </div>
    </Slide>
  );
};
