import { FC } from "react";
import { useApp } from "../../App/hooks/useApp";
import { AppMaintenanceModeView } from "../../App/views/AppMaintenanceModePage";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useMainInit } from "../hooks/useMainInit";
import { MainRoutes } from "./MainRoutes";

export const MainContentView: FC = () => {
  const { isInitializing, error } = useMainInit();
  const isMaintenanceMode = useApp((state) => state.app?.isMaintenanceMode);

  if (isInitializing) return <LoadingPage />;
  if (error) return <ErrorPage error={error} />;
  if (isMaintenanceMode) return <AppMaintenanceModeView />;

  return <MainRoutes />;
};
