import { SubscriptionStatus } from "@super-real/types/types/SubscriptionStatus";
import { FC, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { AppDownloadDialog } from "../../App/views/AppDownloadDialog";
import { useProducts } from "../../Checkout/hooks/useProducts";
import { CheckoutDialog } from "../../Checkout/views/CheckoutDialog";
import { handleErrorWithoutToast } from "../../Common/helpers/handleError";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingView } from "../../Common/views/LoadingView";
import { MainPage } from "../../Main/views/MainPage";
import { useSubscriptions } from "../../Payment/hooks/useSubscriptions";
import { useTalentsByTalentTag } from "../../Talent/hooks/useTalentsByTalentTag";
import { useMyUser } from "../../User/hooks/useMyUser";
import { captureChatLastUserInteractionCallable } from "../callables/captureChatLastUserInteractionCallable";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { useChatsByTalentId } from "../hooks/useChatsByTalentId";
import { useExpensesQueue } from "../hooks/useExpensesQueue";
import { ChatHeadView } from "./ChatHeadView";
import { ChatImageDialog } from "./ChatImageDialog";
import { ChatInputView } from "./ChatInputView";
import { ChatMessageListView } from "./ChatMessageListView";

export const ChatPage: FC = () => {
  const { talentTag } = useParams<"talentTag">();
  const [talents, isLoadingTalent, talentError] =
    useTalentsByTalentTag(talentTag);
  const talent = talents.length ? talents[0] : undefined;
  const userId = useMyUser((state) => state.user?.id);
  const [products] = useProducts();
  const [subscriptions, isLoadingSubscription, subscriptionsError] =
    useSubscriptions(
      userId && talent
        ? [
            ["userId", "==", userId],
            ["talentId", "==", talent.id],
            [
              "status",
              "in",
              ["ACTIVE", "PAUSED"] satisfies SubscriptionStatus[],
            ],
          ]
        : null
    );
  const subscription = subscriptions.length ? subscriptions[0] : undefined;
  const [chats, , chatError] = useChatsByTalentId(talent?.id);
  const chat = chats.length ? chats[0] : undefined;

  const talentNotFoundError =
    !isLoadingTalent && !talent ? new Error("Cannot find talent.") : undefined;
  const error =
    talentError || chatError || subscriptionsError || talentNotFoundError;

  useLayoutEffect(() => {
    useChatPageContext.getState().update({
      talent,
      chat,
      subscription,
      isLoadingSubscription,
      products,
    });
  }, [chat, isLoadingSubscription, subscription, talent, products]);

  useEffect(() => {
    return () => {
      useChatPageContext.getState().reset();
      useExpensesQueue.getState().reset();
    };
  }, []);

  useEffect(() => {
    if (!chat?.id) return;
    void captureChatLastUserInteractionCallable({ chatId: chat.id }).catch(
      handleErrorWithoutToast
    );
  }, [chat?.id]);

  if (error) return <ErrorPage error={error} />;

  return (
    <MainPage>
      <ChatHeadView talent={talent} />
      {isLoadingTalent && <LoadingView />}
      {!isLoadingTalent && <ChatMessageListView />}
      <ChatInputView />
      <CheckoutDialog />
      <ChatImageDialog />
      {/* ATTENTION: Make sure that the app download dialog is below the checkout dialog! */}
      <AppDownloadDialog />
    </MainPage>
  );
};
