import { ExpenseAction } from "@super-real/types";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useChatPageContext } from "../hooks/useChatPageContext";

export const calculateCreditsForAction = (action: ExpenseAction): number => {
  const { talent, chat, subscription } = useChatPageContext.getState();
  const { user } = useMyUser.getState();

  if (!talent) return Infinity;
  if (!chat) return Infinity;

  if (user?.managedTalentIds?.includes(talent.id)) {
    return NO_CREDITS;
  }

  switch (action) {
    case "SEND_MESSAGE":
      if (subscription?.status === "ACTIVE") return NO_CREDITS;
      return talent.creditsToSendMessage;

    case "UNLOCK_IMAGE":
      if (
        !chat.hasUserInteractionWithUnlockImageAttachment &&
        talent.creditsToUnlockFirstImage !== undefined
      ) {
        return talent.creditsToUnlockFirstImage;
      }

      return talent.creditsToUnlockImage;

    case "UNLOCK_AUDIO":
      return talent.creditsToUnlockAudio;
  }
};

const NO_CREDITS = 0;
