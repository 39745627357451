import { Divider, Stack, Typography } from "@mui/material";
import { Talent } from "@super-real/types";
import { FC } from "react";
import { useChatPageContext } from "../../Chat/hooks/useChatPageContext";

interface Props {
  talent: Talent;
  bgcolor: string;
}

export const TalentCreditsPricingView: FC<Props> = (props) => {
  const { talent, bgcolor } = props;
  const hasSubscription = useChatPageContext((state) => !!state.subscription);

  return (
    <Stack direction="row" justifyContent="center">
      <Stack
        direction="row"
        spacing={1.5}
        textAlign="center"
        bgcolor={bgcolor}
        borderRadius={10}
        px={1.5}
        py={1}
      >
        <Typography variant="body2" fontSize={10}>
          💬 Message = {hasSubscription && "free"}
          {!hasSubscription && <>💎&nbsp;{talent.creditsToSendMessage}</>}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" fontSize={10}>
          📸 Image = 💎&nbsp;{talent.creditsToUnlockImage}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" fontSize={10}>
          🎤 Voice = 💎&nbsp;{talent.creditsToUnlockAudio}
        </Typography>
      </Stack>
    </Stack>
  );
};
