import { FirebaseError } from "firebase/app";
import {
  AuthErrorCodes,
  EmailAuthProvider,
  isSignInWithEmailLink,
  linkWithCredential,
  signInWithEmailLink,
} from "firebase/auth";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import {
  handleError,
  handleErrorWithoutToast,
} from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { updateUserCallable } from "../../User/callables/updateUserCallable";
import { auth } from "../consts/FIREBASE";

const SearchParams = z.object({
  email: z.string(),
  mode: z.enum(["signIn"]),
});

interface Args {
  pathname: string;
  search: string;
  onStart: () => void;
  onCompletion: () => void;
}

export async function processEmailLink(args: Args) {
  const { pathname, search, onStart, onCompletion } = args;
  const emailLink = `${ENVIRONMENT.domain}${pathname}${search}`;
  const authUser = auth.currentUser;

  // Get the search params
  const unsafeSearchParams = Object.fromEntries(new URLSearchParams(search));
  const result = SearchParams.safeParse(unsafeSearchParams);
  if (!result.success) return;
  const { mode, email } = result.data;

  // Do not throw for the following conditions
  if (mode !== "signIn") return;
  if (!authUser) return;
  if (!authUser.isAnonymous) return;
  if (!isSignInWithEmailLink(auth, emailLink)) return;

  try {
    onStart();

    const credential = EmailAuthProvider.credentialWithLink(email, emailLink);
    await linkWithCredential(authUser, credential);
    logEvent("signed_up", authUser.providerId);
    void updateUserCallable({ email }).catch(handleErrorWithoutToast);
  } catch (error) {
    if (!(error instanceof FirebaseError)) return;
    if (error.code !== AuthErrorCodes.EMAIL_EXISTS) return;

    if (ENVIRONMENT.nodeEnv === "development") {
      return toast.error(
        "'signInWithEmailLink' is not supported by the emulator if 'linkWithCredential' failed before."
      );
    }

    try {
      await signInWithEmailLink(auth, email, emailLink);
      logEvent("signed_in");
    } catch (error) {
      handleError(error);
    }
  } finally {
    // Please note: We also complete, when an error is thrown
    onCompletion();
  }
}
