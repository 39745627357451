import { Skeleton, styled, SxProps } from "@mui/material";
import { Dimensions } from "@super-real/types";
import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react";
import { useStorageFile } from "../hooks/useStorageFile";

interface Props
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  filePath: string;
  dimensions?: Dimensions;
  sx?: SxProps;
}

export const StorageImage: FC<Props> = (props) => {
  const { filePath, dimensions, ...rest } = props;
  const { url, isLoading } = useStorageFile(filePath);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width={dimensions?.width || "100%"}
        height={dimensions?.height || "100%"}
        sx={props.sx}
      />
    );
  }

  return <Image src={url} alt="" {...rest} />;
};

const Image = styled("img")({});
