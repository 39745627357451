import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import { Button, Link, Stack, Typography } from "@mui/material";
import { linkWithRedirect } from "firebase/auth";
import { FC, useCallback, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { TERMS_AND_CONDITIONS_URL } from "../../Common/consts/TERMS_AND_CONDITIONS_URL";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { auth } from "../consts/FIREBASE";
import { getAuthProvider, SignInMethod } from "../helpers/getAuthProvider";
import { useIsWebView } from "../hooks/useIsWebView";
import { FirebaseAuthCopyUrlDialog } from "./FirebaseAuthCopyUrlDialog";
import { FirebaseAuthEmailForm } from "./FirebaseAuthEmailForm";

interface Props {
  size?: "small";
}

export const FirebaseAuthView: FC<Props> = (props) => {
  const { size } = props;
  const isWebView = useIsWebView();
  const [authState] = useAuthState(auth);
  const [isCopyUrlDialogOpen, setIsCopyUrlDialogOpen] = useState(false);

  const submit = useCallback(
    async (signInMethod: SignInMethod) => {
      try {
        if (!authState) throw new Error("No user found.");
        await linkWithRedirect(authState, getAuthProvider(signInMethod));
      } catch (error) {
        handleError(error);
      }
    },
    [authState]
  );

  return (
    <>
      <Stack spacing={size === "small" ? 1 : 2}>
        <FirebaseAuthEmailForm size={size} />
        {size !== "small" && <Stack textAlign="center">OR</Stack>}
        <Button
          size="large"
          variant="contained"
          startIcon={<GoogleIcon />}
          onClick={() => {
            if (isWebView) {
              setIsCopyUrlDialogOpen(true);
            } else {
              logEvent("clicked_auth_google");
              void submit("google.com");
            }
          }}
        >
          Sign in with Google
        </Button>
        <Button
          size="large"
          variant="contained"
          startIcon={<AppleIcon />}
          onClick={() => {
            logEvent("clicked_auth_apple");
            void submit("apple.com");
          }}
        >
          Sign in with Apple
        </Button>
        <Typography variant="caption" color="text.secondary" textAlign="center">
          By continuing, you agree to our{" "}
          <Link href={TERMS_AND_CONDITIONS_URL} target="_blank" color="inherit">
            Terms
          </Link>{" "}
          and{" "}
          <Link href={TERMS_AND_CONDITIONS_URL} target="_blank" color="inherit">
            Privacy Policy
          </Link>
          .
        </Typography>
      </Stack>
      <FirebaseAuthCopyUrlDialog
        isOpen={isCopyUrlDialogOpen}
        close={() => setIsCopyUrlDialogOpen(false)}
      />
    </>
  );
};
