import { Avatar, Stack } from "@mui/material";
import { FC, PropsWithChildren, ReactNode } from "react";

const AVATAR_SIZE = 28;

interface Props extends PropsWithChildren {
  senderType: "USER" | "TALENT";
  profilePictureUrl?: string;
  actions?: ReactNode;
  maxWidth?: string;
  fullWidth?: boolean;
}

export const ChatMessageLayoutView: FC<Props> = (props) => {
  const {
    senderType,
    profilePictureUrl,
    children,
    actions,
    maxWidth,
    fullWidth,
  } = props;

  return (
    <Stack
      px={1}
      direction={senderType === "TALENT" ? "row" : "row-reverse"}
      justifyContent="flex-start"
      spacing={1}
    >
      {senderType === "TALENT" && (
        <Avatar
          src={profilePictureUrl}
          sx={{
            border: "2px solid white",
            width: AVATAR_SIZE,
            height: AVATAR_SIZE,
          }}
        />
      )}
      <Stack
        maxWidth={maxWidth || "80%"}
        width={fullWidth ? "100%" : undefined}
      >
        {!!children && (
          <Stack
            p={1.5}
            bgcolor={senderType === "TALENT" ? "grey.500" : "grey.700"}
            borderRadius={4}
            border="1px solid"
            borderColor="grey.400"
            position="relative"
          >
            {children}
          </Stack>
        )}
        {actions}
      </Stack>
    </Stack>
  );
};
