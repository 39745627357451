import { Collapse, Link, Stack, styled, Typography } from "@mui/material";
import { PublicTalent } from "@super-real/types";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";

interface Props {
  publicTalent?: PublicTalent;
}

export const HomeTalentView: FC<Props> = (props) => {
  const { publicTalent } = props;
  const [isNameVisible, setIsNameVisible] = useState(false);

  if (!publicTalent) {
    return <Stack width={1} height={SIZE + BORDER * 2} />;
  }

  return (
    <Link
      sx={{ background: MAIN_PALETTE.background.gradient }}
      p={`${BORDER}px`}
      borderRadius={SIZE}
      width={SIZE + BORDER * 2}
      height={SIZE + BORDER * 2}
      to={`/${publicTalent.tag}`}
      component={NavLink}
      onMouseOver={() => setIsNameVisible(true)}
      onMouseOut={() => setIsNameVisible(false)}
      position="relative"
    >
      <Stack
        position="absolute"
        top={BORDER}
        left={BORDER}
        right={BORDER}
        bottom={BORDER}
        borderRadius={SIZE}
        overflow="hidden"
        justifyContent="flex-end"
      >
        <Collapse orientation="vertical" in={isNameVisible}>
          <Stack bgcolor="rgba(0, 0, 0, 0.7)" pb={1.5} pt={1}>
            <Typography
              variant="ah5"
              textAlign="center"
              color="common.white"
              fontSize={14}
            >
              {publicTalent.label || publicTalent.name}
            </Typography>
          </Stack>
        </Collapse>
      </Stack>
      <Image src={publicTalent.profilePictureUrl} />
    </Link>
  );
};

const BORDER = 2;
const SIZE = 95;

const Image = styled("img")({
  width: SIZE,
  height: SIZE,
  borderRadius: SIZE,
  objectFit: "cover",
});
