import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useChatPageContext } from "../../Chat/hooks/useChatPageContext";
import { logEvent } from "../../Common/helpers/logEvent";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { TalentCreditsPricingView } from "../../Talent/views/TalentCreditsPricingView";
import { useCheckoutDialog } from "../hooks/useCheckoutDialog";
import { CheckoutOneTimeView } from "./CheckoutOneTimeView";

export const CheckoutDialog: FC = () => {
  const talent = useChatPageContext((state) => state.talent);
  const { isOpen, close } = useCheckoutDialog();

  useEffect(() => {
    if (!isOpen) return;
    logEvent("opened_checkout_dialog");
  }, [isOpen]);

  if (!talent) return null;

  return (
    <DrawerDialog open={isOpen}>
      <DialogContent>
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography variant="ah2" textAlign="center">
              Top up your credits
            </Typography>
            <TalentCreditsPricingView
              talent={talent}
              bgcolor="rgba(255, 255, 255, 0.1)"
            />
          </Stack>
          <CheckoutOneTimeView talent={talent} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Go back
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
