import { Skeleton, Stack, SxProps } from "@mui/material";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  AUDIO_PLAYER_BAR_SPACING,
  AUDIO_PLAYER_BAR_WIDTH,
  AUDIO_PLAYER_SIZE,
} from "./AUDIO_PLAYER";

const ANIMATION_DURATION = 1;
const BAR_HEIGHT_STEPS = 6;

interface Props {
  width?: string | number;
  sx?: SxProps;
}

export const AudioSkeleton: FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!ref.current) return;
    const { width } = ref.current.getBoundingClientRect();
    setWidth(width);
  }, []);

  const bars = useMemo(() => {
    if (!width) return 0;
    return Math.floor(
      width / (AUDIO_PLAYER_BAR_WIDTH + AUDIO_PLAYER_BAR_SPACING)
    );
  }, [width]);

  const barHeights = Array.from({ length: BAR_HEIGHT_STEPS })
    .fill(0)
    .map((_, index) => (AUDIO_PLAYER_SIZE / BAR_HEIGHT_STEPS) * (index + 1));

  return (
    <Stack
      ref={ref}
      height={AUDIO_PLAYER_SIZE}
      width={props.width}
      direction="row"
      alignItems="center"
      sx={{ ...props.sx, whiteSpace: "nowrap", overflow: "hidden" }}
      spacing={`${AUDIO_PLAYER_BAR_SPACING}px`}
    >
      {Array.from({ length: bars }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          height={barHeights[Math.floor(Math.random() * barHeights.length)]}
          width={AUDIO_PLAYER_BAR_WIDTH}
          animation="pulse"
          sx={{
            flexShrink: 0,
            animationDelay: `${index * (ANIMATION_DURATION / bars)}s`,
            animationDuration: `${bars * (ANIMATION_DURATION / bars)}s`,
          }}
        />
      ))}
    </Stack>
  );
};
