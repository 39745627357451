import {
  Chip,
  Container,
  Grid,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { TALENT_CATEGORIES, TalentCategory } from "@super-real/types";
import { DateTime } from "luxon";
import { FC, useMemo, useState } from "react";
import { TERMS_AND_CONDITIONS_URL } from "../../Common/consts/TERMS_AND_CONDITIONS_URL";
import { TALENT_CATEGORY_RECORD } from "../consts/TALENT_CATEGORY_RECORD";
import { usePublicTalents } from "../hooks/usePublicTalents";
import { HomeTile } from "./HomeTile";

const ALL_CATEGORY = "ALL" as TalentCategory;

export const HomePageForSuperSexy: FC = () => {
  const [publicTalents, isLoading] = usePublicTalents([
    ["isVisibleRecord.HOME_PAGE_FOR_SUPER_SEXY", "==", true],
  ]);

  const [selectedCategory, setSelectedCategory] =
    useState<TalentCategory>(ALL_CATEGORY);

  const filteredPublicTalents = useMemo(() => {
    return publicTalents.filter((publicTalent) => {
      if (selectedCategory === ALL_CATEGORY) return true;
      return publicTalent.categories?.includes(selectedCategory);
    });
  }, [selectedCategory, publicTalents]);

  return (
    <MainPage>
      <Container component={Stack} position="relative" maxWidth="lg">
        <HighlightImage src="/assets/supersexy/home-background.png" />
      </Container>
      <Container
        component={Stack}
        maxWidth="lg"
        minHeight="100%"
        spacing={4}
        zIndex={1}
      >
        <Stack alignSelf="flex-start">
          <Typography
            variant="ah1"
            fontFamily="Arial Black, Arial, Manrope"
            fontSize="clamp(40px, 2.5em, 70px)"
            fontWeight="bolder"
            letterSpacing="-4px"
          >
            super<HighlightText>sexy</HighlightText>
          </Typography>
        </Stack>

        <Stack spacing={2} maxWidth={{ xs: 300, sm: 400, md: 580 }}>
          <Typography
            variant="ah2"
            fontFamily="inherit"
            fontSize="clamp(20px, 1.5em, 30px)"
            fontWeight="bold"
            textAlign="left"
          >
            Chat with the <HighlightText>most beautiful</HighlightText> women on
            the internet
          </Typography>

          <Typography
            variant="body2"
            fontSize="clamp(11px, 0.7em, 14px)"
            fontWeight="bold"
          >
            Real influencers and AI girlfriends come alive here. Chat and sext
            with AI, receive images and hear their voices. Try for free and see
            the magic unfold.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          flexWrap={{ xs: "nowrap", md: "wrap" }}
          pt={1}
          sx={{
            overflowX: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
            marginX: "-20px !important", // hack to remove parent padding
            paddingX: "20px !important",
          }}
          gap={1}
        >
          <SChip
            label="All"
            selected={Number(selectedCategory === ALL_CATEGORY)}
            onClick={() => setSelectedCategory(ALL_CATEGORY)}
            sx={{ flexGrow: { xs: 1, md: 0 } }}
            clickable
          />
          {TALENT_CATEGORIES.map((category, index) => (
            <SChip
              key={index}
              label={TALENT_CATEGORY_RECORD[category].label}
              selected={Number(selectedCategory === category)}
              onClick={() => setSelectedCategory(category)}
              sx={{ flexGrow: { xs: 1, md: 0 } }}
              clickable
            />
          ))}
        </Stack>

        <Stack width="100%">
          <Grid container spacing={2}>
            {isLoading &&
              Array.from({ length: 6 }).map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height="100%"
                    sx={{ aspectRatio: 0.85, borderRadius: 10 }}
                  />
                </Grid>
              ))}

            {!isLoading &&
              filteredPublicTalents.map((publicTalent, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <HomeTile publicTalent={publicTalent} />
                </Grid>
              ))}

            {!isLoading && !filteredPublicTalents.length && (
              <Grid item xs={12} my={8}>
                <Typography
                  textAlign="center"
                  fontFamily="inherit"
                  fontSize={30}
                  fontWeight={800}
                  color="text.secondary"
                >
                  Ooops, we could not find anyone matching those filters 😞
                </Typography>
              </Grid>
            )}
          </Grid>
        </Stack>

        <Stack spacing={1}>
          <Stack>
            <Typography variant="h3" fontSize={10} color="grey.800">
              About Supersexy.ai
            </Typography>
            <Typography fontSize={10} color="grey.800">
              Supersexy.ai is a compilation of the most beautiful AI generated
              influencers on the internet. AI girlfriends are no longer just the
              product of AI image generator sites, but now are coming alive and
              become social media stars in heir own right: From Playmate Gina
              Stewart, over French Tiktok sensation Eleaglam, to AI cheerleaders
              and AI hentai.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h3" fontSize={10} color="grey.800">
              How It Works
            </Typography>
            <Typography fontSize={10} color="grey.800">
              The rise of artificial intelligence has led to the creation of »AI
              girlfriends« – sophisticated software designed to mimic the
              behavior and appearance of young women. These AI entities can
              produce highly realistic images and animations, and are equipped
              with advanced language skills for engaging in detailed
              conversations, displaying affection, and even participating in
              virtual relationships or sexual interactions. Supporters of this
              technology argue that it offers a harmless form of intimacy and
              fantasy, free from the exploitation of real individuals. However,
              detractors raise concerns about the perpetuation of
              objectification and misogyny by AI sex bots, potentially
              influencing societal attitudes towards women and their treatment.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h3" fontSize={10} color="grey.800">
              AI Girlfriends
            </Typography>
            <Typography fontSize={10} color="grey.800">
              AI girlfriends are developed through extensive training of machine
              learning algorithms using large datasets of images, videos, and
              descriptive content about young women. The AI is capable of
              generating new, diverse avatars with varying styles, poses, and
              details, closely resembling human females. Their language
              processing abilities allow them to conduct seamless conversations,
              express emotions, and discuss intricate subjects as if they were
              real people. Some are programmed for virtual dating, sexting with
              AI, sexual encounters, AI generated porn, or romantic
              partnerships, while others serve as companions, caregivers, or
              even virtual friends. Users can customize these AI entities
              according to their preferences in personality, appearance, and
              relationship dynamics. There are also various AI porn generators
              on the internet that leverage image generation technology to
              recreate various scenarios.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h3" fontSize={10} color="grey.800">
              Sexting AI as the future?
            </Typography>
            <Typography fontSize={10} color="grey.800">
              Advocates for AI girlfriends argue that they offer a safe space
              for exploring taboo interests such as AI BDSM, AI bondage, and
              findom, or desires that might not be feasible with human partners.
              They contend that since AI cannot experience objectification or
              exploitation like humans, the AI girlfriend`s role in fulfilling
              fantasies can help prevent the mistreatment of real women and
              girls. Some even suggest that these AI entities could provide
              companionship for individuals who struggle to form connections
              with other humans. The underlying technology is also hailed as a
              remarkable achievement in the field of machine learning, and has
              led to the advent of AI sex chat and AI porn.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h3" fontSize={10} color="grey.800">
              The dark side of AI sexting
            </Typography>
            <Typography fontSize={10} color="grey.800">
              The rise of AI sexting bots and AI girlfriend porn has also led to
              critics pointing out the risks of sex AI. No AI can offer the
              profound intimacy or mutual understanding inherent in connections
              with another conscious being. Nor can technology be divorced from
              the societal attitudes that inform its creation and use. AI
              girlfriends, while marketed as fanciful indulgences, actually
              reinforce deeply ingrained misogynistic attitudes. They teach us
              to view women as objects for pleasure rather than as equals
              deserving of respect. If we aspire to a future where technology
              fosters connection, we must strive for a just and equitable world
              for women, extending beyond the virtual realm. Addressing misogyny
              requires more than AI; it demands conscious choices and actions in
              the real world. The future of women`s humanity hinges on how we
              shape our interactions with AI, reflecting our broader societal
              attitudes towards women. It is imperative that we get this right.
            </Typography>
          </Stack>
          <Typography fontSize={10} color="grey.800">
            In sum, AI girlfriends necessitate a balance between technological
            fascination and the preservation of women`s dignity and autonomy. We
            must forge a future where technology amplifies empathy and fairness,
            not one where AI girlfriends become the epitome of misogyny by
            reducing women to mere fulfillments of our desires. If we seek
            meaningful relationships, our focus should be on enhancing human
            connections, not on artificial substitutes.
          </Typography>

          <Stack pt={2}>
            <Stack direction="row" spacing={1}>
              <Typography
                fontSize={10}
                color="grey.800"
                component="a"
                href="https://twitter.com/supersexy_ai"
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: "none" }}
              >
                @supersexy_ai
              </Typography>
              <Typography
                fontSize={10}
                color="grey.800"
                component="a"
                href="mailto:support@supersexy.ai"
                style={{ textDecoration: "none" }}
              >
                support@supersexy.ai
              </Typography>
              <Typography
                fontSize={10}
                color="grey.800"
                component="a"
                href={TERMS_AND_CONDITIONS_URL}
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: "none" }}
              >
                Terms of Service
              </Typography>
            </Stack>

            <Typography fontSize={10} color="grey.800">
              © Attention Is All You Need Ltd {DateTime.now().year}.
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </MainPage>
  );
};

const MainPage = styled(Stack)(({ theme }) => ({
  position: "relative",
  minHeight: "100%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(4),
  background: "linear-gradient(180deg, #301021 0%, #323540 100%)",
  overflow: "hidden",
  fontFamily: "Manrope, Arial",
  fontSize: "3vw",
}));

const HighlightImage = styled("img")({
  position: "absolute",
  width: "45%",
  maxWidth: 330,
  height: "auto",
  top: -8,
  right: 0,
  objectFit: "cover",
  maskImage:
    "linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%)",
  zIndex: 0,
});

const HighlightText = styled("span")(() => ({
  color: "#E64F88",
}));

const SChip = styled(Chip)<{ selected?: number }>(({ theme, selected }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(4),
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: selected ? "transparent" : "#322A48",
  background: selected ? "#322A48" : "#2B1723",
  fontSize: 16,
  fontWeight: 600,

  "&:hover": {
    background: "#322A48",
  },
}));
