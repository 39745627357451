import { Timestamp } from "firebase/firestore";
import { useCallback, useRef } from "react";
import { v4 } from "uuid";
import { EventName, logEvent } from "../../Common/helpers/logEvent";
import { useMyUser } from "../../User/hooks/useMyUser";
import { TEMPORARY_CHAT_ID } from "../consts/TEMPORARY_CHAT_ID";
import { useAsyncChat } from "./useAsyncChat";
import { useChatMessageAction } from "./useChatMessageAction";
import { useExpensesQueue } from "./useExpensesQueue";
import { useLocalChatMessages } from "./useLocalChatMessages";

interface Params {
  setTextField?: (text: string) => void;
  isSuggestion?: boolean;
}

export const useSendChatMessage = (params: Params) => {
  const { setTextField, isSuggestion } = params;
  const eventName: EventName = isSuggestion
    ? "sent_chat_message_suggestion"
    : "sent_chat_message";
  const inputRef = useRef<HTMLInputElement>();

  const sendChatMessage = useCallback(
    async (untrimmedText: string) => {
      const text = untrimmedText.trim();

      if (!text) return;

      setTextField?.("");
      inputRef.current?.focus();
      useChatMessageAction.getState().hide();

      const localId = v4();
      useLocalChatMessages.getState().add({
        id: localId,
        chatId: TEMPORARY_CHAT_ID,
        text,
        senderType: "USER",
        senderId: useMyUser.getState().user?.id || "USER",
        localId,
        createdAt: Timestamp.now(),
      });

      const chat = await useAsyncChat.getState().getChat();
      const { addExpense } = useExpensesQueue.getState();

      addExpense({
        action: "SEND_MESSAGE",
        chatId: chat.id,
        localId,
        text,
        onSuccess: () => {
          logEvent(eventName);
        },
        onError: () => {
          useLocalChatMessages.getState().reset();
        },
      });
    },
    [eventName, setTextField]
  );

  return { sendChatMessage, inputRef };
};
