import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { addReactionFeedbackToChatMessageCallable } from "../callables/addReactionFeedbackToChatMessageCallable";

const FormValues = z.object({
  reactionFeedback: z.string().nonempty(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  open: boolean;
  chatId?: string;
  chatMessageId: string;
  close: () => void;
}

export const ChatMessageReactionFeedbackDialog: FC<Props> = (props) => {
  const { open, chatId, chatMessageId, close } = props;

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { reactionFeedback: "" },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!chatId) return;

      try {
        await addReactionFeedbackToChatMessageCallable({
          chatId,
          chatMessageId,
          reactionFeedback: formValues.reactionFeedback,
        });

        reset();
        close();
      } catch (error) {
        handleError(error);
      }
    },
    [chatId, chatMessageId, reset, close]
  );

  const isSubmitting = formState.isSubmitting;

  return (
    <DrawerDialog open={open} onClose={close}>
      <DialogContent>
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={4}>
          <Stack>
            <Typography variant="ah3">Provide additional feedback</Typography>
            <Typography variant="body2">
              What is the issue, how could it be improved?
            </Typography>
          </Stack>

          <ControlledTextField
            control={control}
            name="reactionFeedback"
            placeholder="Write your message here."
            multiline
            rows={4}
            disabled={isSubmitting}
            fullWidth
          />

          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Send
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Close
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
