import { isTalent } from "@super-real/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useTalentsByTalentTag(talentTag?: string) {
  return useValidCollectionData(
    talentTag
      ? query(
          getCollectionRef("talents"),
          where("tag", "==", talentTag.toLowerCase())
        )
      : null,
    isTalent
  );
}
