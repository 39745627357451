import { Button, Stack } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { usePrevious } from "../../Common/hooks/usePrevious";
import { CloseableAlert } from "../../Common/views/CloseableAlert";
import { useMainInit } from "../../Main/hooks/useMainInit";
import { useChatMessages } from "../hooks/useChatMessages";
import { useChatMessageVisibility } from "../hooks/useChatMessageVisibility";
import { useChatPageContext } from "../hooks/useChatPageContext";
import { ChatMessageActionView } from "./ChatMessageActionView";
import { ChatMessageSuggestionsView } from "./ChatMessageSuggestionsView";
import { ChatMessageView } from "./ChatMessageView";
import { ChatTypingIndicator } from "./ChatTypingIndicator";

export const ChatMessageListView: FC = () => {
  const talent = useChatPageContext((state) => state.talent);
  const scrollRef = useChatPageContext((state) => state.scrollRef);
  const isInitialScroll = useRef(true);
  const previousScrollPosition = useRef<number>(0);
  const previousScrollHeight = useRef<number>(0);
  const latestChatMessageId = useRef<string>();
  const [isVisible, setIsVisible] = useState(useMainInit.getState().isNewUser);
  const [chatMessages, , , { loadMore, hasMore }] = useChatMessages();
  const prevChatMessages = usePrevious(chatMessages);
  const hasAcknowledgedAiDisclaimer = useLocalStorage(
    (state) => state.data.hasAcknowledgedAiDisclaimer
  );
  const hasHiddenChatMessages = useChatMessageVisibility(
    (state) => state.hasHiddenChatMessages
  );

  useEffect(() => {
    const scrollRef = useChatPageContext.getState().scrollRef;
    const prevChatMessagesLength = prevChatMessages?.length ?? 0;
    const chatMessagesLength = chatMessages.length;
    const latestChatMessage = chatMessages[0];
    const hasAddedMessages = Boolean(
      chatMessagesLength - prevChatMessagesLength
    );

    if (typeof window === "undefined") return;
    if (!scrollRef.current) return;
    if (!latestChatMessage) return;
    if (!hasAddedMessages) return;

    // On initial load, scroll to the bottom
    if (isInitialScroll.current) {
      scrollRef.current.scrollIntoView({ behavior: "instant" });
      latestChatMessageId.current = latestChatMessage.id;
      isInitialScroll.current = false;
      setIsVisible(true); // we hide an empty chat to avoid flickering
      return;
    }

    // Scroll to the bottom, when a new message was added
    if (latestChatMessageId.current !== latestChatMessage?.id) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      latestChatMessageId.current = latestChatMessage?.id;
      return;
    }

    // Stay at the same position, when loading old messages
    if (latestChatMessageId.current === latestChatMessage?.id) {
      const nextScrollHeight = window.document.body.scrollHeight;
      const deviation = nextScrollHeight - previousScrollHeight.current;
      window.scrollTo(0, previousScrollPosition.current + deviation);

      // reset to prevent flickering on a new message
      previousScrollPosition.current = 0;
      previousScrollHeight.current = 0;
      return;
    }
  }, [chatMessages, prevChatMessages]);

  const onClickLoadMore = useCallback(() => {
    if (typeof window === "undefined") return;

    // save current scroll position/height to restore it after loading more messages
    previousScrollPosition.current = window.scrollY;
    previousScrollHeight.current = window.document.body.scrollHeight;

    loadMore();
  }, [loadMore]);

  const isTyping =
    hasHiddenChatMessages || chatMessages[0]?.senderType !== "TALENT";

  return (
    <Stack
      direction="column-reverse"
      flex={1}
      p={1}
      spacing={2}
      visibility={isVisible ? "visible" : "hidden"}
    >
      <Stack ref={scrollRef} />
      {isTyping && <ChatTypingIndicator />}
      {!isTyping && <ChatMessageSuggestionsView chatMessages={chatMessages} />}
      {!isTyping && <ChatMessageActionView />}
      {chatMessages.map((chatMessage, index) => (
        <ChatMessageView
          key={chatMessage.localId || chatMessage.id}
          chatMessage={chatMessage}
          isLastMessage={index === 0}
        />
      ))}
      {hasMore && (
        <Button
          variant="text"
          onClick={onClickLoadMore}
          sx={{ alignSelf: "center" }}
        >
          load older messages
        </Button>
      )}
      {talent && (
        <Stack p={1} flex={1}>
          <CloseableAlert
            severity="warning"
            open={!hasAcknowledgedAiDisclaimer}
            onClose={() =>
              useLocalStorage
                .getState()
                .setData({ hasAcknowledgedAiDisclaimer: true })
            }
          >
            Our AI companions are currently in beta, so don`t take what they say
            seriously. We hold no liability for the content.
          </CloseableAlert>
        </Stack>
      )}
    </Stack>
  );
};
