import {
  FirestoreError,
  limit,
  onSnapshot,
  Query,
  query,
  queryEqual,
  QuerySnapshot,
  startAfter,
  Unsubscribe,
} from "firebase/firestore";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  errorAction,
  loadedAction,
  loadingAction,
  loadMoreAction,
  resetAction,
} from "./actions";
import { initialState, reducer } from "./reducer";

const DEFAULT_PAGE_SIZE = 50;

interface PaginationController {
  hasMore: boolean;
  loadMore: () => void;
}

export const usePaginatedCollection = <T>(
  nextQuery: Query | null | undefined,
  isT: (obj: unknown) => obj is T,
  options?: { pageSize: number }
): [
  items: T[],
  isLoading: boolean,
  error: FirestoreError | null,
  controller: PaginationController
] => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const subscriptionsRef = useRef<Unsubscribe[]>([]);
  const [persistedQuery, setPersistedQuery] = useState(nextQuery);

  const unsubscribeSubscriptions = useCallback(() => {
    subscriptionsRef.current.forEach((unsubscribe) => unsubscribe());
    subscriptionsRef.current = [];
  }, []);

  // Persist query
  useEffect(() => {
    if (persistedQuery && nextQuery && queryEqual(nextQuery, persistedQuery)) {
      return;
    }

    setPersistedQuery(nextQuery);
    dispatch(resetAction());
    unsubscribeSubscriptions();
  }, [nextQuery, persistedQuery, unsubscribeSubscriptions]);

  // On unmount unsubscribe from all subscriptions
  useEffect(() => {
    return unsubscribeSubscriptions;
  }, [unsubscribeSubscriptions]);

  // Subscribe to query
  useEffect(() => {
    if (!persistedQuery) return;

    const pageSize = options?.pageSize || DEFAULT_PAGE_SIZE;
    let q = query(persistedQuery, limit(pageSize));

    if (state.after) {
      q = query(q, startAfter(state.after));
    }

    const onSuccess = (snapshot: QuerySnapshot) => {
      dispatch(loadedAction({ snapshot, pageSize }));
    };

    const onError = (error: FirestoreError) => {
      dispatch(errorAction(error));
    };

    dispatch(loadingAction());
    const subscription = onSnapshot(q, onSuccess, onError);
    subscriptionsRef.current.push(subscription);
  }, [options?.pageSize, persistedQuery, state.after]);

  const elements = useMemo(() => {
    return state.elements.reduce<T[]>((result, doc) => {
      const data = doc.data();
      if (isT(data)) result.push(data);
      return result;
    }, []);
  }, [state.elements, isT]);

  const loadMore = useCallback(() => {
    if (!state.hasMoreElements || state.isLoading) return;
    return dispatch(loadMoreAction());
  }, [state.hasMoreElements, state.isLoading]);

  const controller = useMemo<PaginationController>(
    () => ({
      hasMore: state.hasMoreElements,
      loadMore,
    }),
    [loadMore, state.hasMoreElements]
  );

  return [elements, state.isLoading, state.error, controller];
};
