import { useEffect, useMemo } from "react";
import { useChatMessagesByChatId } from "./useChatMessagesByChatId";
import { useChatMessageVisibility } from "./useChatMessageVisibility";
import { useChatPageContext } from "./useChatPageContext";
import { useInitialChatMessages } from "./useInitialChatMessages";
import { useLocalChatMessages } from "./useLocalChatMessages";

/**
 * This hook combines all chat messages:
 *
 * Server: chat messages loaded from Firestore
 * Local: chat messages that have just been sent by the user, and are not yet saved to Firestore
 * Initial: very first chat messages that are shown, when a `user` and `chat` have not been created yet
 */
export function useChatMessages(): ReturnType<typeof useChatMessagesByChatId> {
  const talent = useChatPageContext((state) => state.talent);
  const chat = useChatPageContext((state) => state.chat);
  const [serverChatMessages, ...rest] = useChatMessagesByChatId(chat?.id);
  const localChatMessages = useLocalChatMessages(
    (state) => state.localChatMessages
  );
  const isHiddenRecord = useChatMessageVisibility(
    (state) => state.isHiddenRecord
  );
  const initialChatMessages = useInitialChatMessages({ talent });

  const chatMessages = useMemo(() => {
    const serverLocalIds = serverChatMessages.map((m) => m.localId);
    const uniqueLocalChatMessages = localChatMessages.filter(
      (l) => !serverLocalIds.includes(l.id)
    );

    if (localChatMessages.length !== uniqueLocalChatMessages.length) {
      setTimeout(() => {
        useLocalChatMessages.setState({
          localChatMessages: uniqueLocalChatMessages,
        });
      });
    }

    return [
      ...uniqueLocalChatMessages,
      ...(initialChatMessages && !serverChatMessages.length
        ? initialChatMessages
        : serverChatMessages),
    ].filter((m) => !isHiddenRecord[m.id]);
  }, [
    serverChatMessages,
    localChatMessages,
    initialChatMessages,
    isHiddenRecord,
  ]);

  useEffect(() => {
    return () => {
      useLocalChatMessages.getState().reset();
      useChatMessageVisibility.getState().reset();
      useChatPageContext.getState().reset();
    };
  }, []);

  return [chatMessages, ...rest];
}
